import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createCategoryApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const getCategoryListApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const updateCategoryApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

const deleteCategoryApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};

export function* createCategorySaga() {
  yield takeEvery(actions.CREATE_CATEGORY_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createCategoryApi, payload);
      if (res?.status_code === 1) {
        yield put({
          type: actions.CREATE_CATEGORY_SUCCESS,
          successMessage: res.message,
        });
      } else {
        yield put({
          type: actions.CREATE_CATEGORY_ERROR,
          error: 'Failed to create category. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_CATEGORY_ERROR,
        error:
          'Something went wrong during category creation. Please try again.',
      });
    }
  });
}

export function* getCategoryListSaga() {
  yield takeEvery(actions.GET_CATEGORY_LIST_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getCategoryListApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.GET_CATEGORY_LIST_SUCCESS,
          categories: res?.data?.categories,
        });
      } else {
        yield put({
          type: actions.GET_CATEGORY_LIST_ERROR,
          error: 'Failed to fetch categories. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_CATEGORY_LIST_ERROR,
        error:
          'Something went wrong while fetching categories. Please try again.',
      });
    }
  });
}

export function* updateCategorySaga() {
  yield takeEvery(actions.UPDATE_CATEGORY_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateCategoryApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.UPDATE_CATEGORY_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.UPDATE_CATEGORY_ERROR,
          error: 'Failed to update category. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_CATEGORY_ERROR,
        error:
          'Something went wrong while updating category. Please try again.',
      });
    }
  });
}

export function* deleteCategorySaga() {
  yield takeEvery(actions.DELETE_CATEGORY_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteCategoryApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.DELETE_CATEGORY_SUCCESS,
          successMessage: res.message,
        });
      } else {
        yield put({
          type: actions.DELETE_CATEGORY_ERROR,
          error: 'Failed to delete category. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_CATEGORY_ERROR,
        error:
          'Something went wrong while deleting category. Please try again.',
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createCategorySaga),
    fork(getCategoryListSaga),
    fork(updateCategorySaga),
    fork(deleteCategorySaga),
  ]);
}
