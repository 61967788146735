import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Typography,
  Tag,
  message,
  Popconfirm,
} from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Redux/Education/actions';
import config from '../../../Services/siteConfig';

const { Title } = Typography;

const Education = () => {
  const dispatch = useDispatch();
  const [education, setEducation] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currenteducation, setCurrenteducation] = useState(null);
  const [form] = Form.useForm();
  const { data, successMessage, errorMessage, actionType } = useSelector(
    (state) => state.QualificationReducer,
  );

  const geteducationList = useCallback(() => {
    try {
      dispatch(
        actions.getQualification(`${config.getQualification}?page=1&limit=20`),
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    geteducationList();
  }, [geteducationList]);

  useEffect(() => {
    if (
      actionType === 'CREATE_QUALIFICATION__SUCCESS' ||
      actionType === 'UPDATE_QUALIFICATION_SUCCESS' ||
      actionType === 'DELETE_QUALIFICATION_SUCCESS'
    ) {
      message.success(successMessage);
      geteducationList();
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage]);

  const handleOk = (values) => {
    try {
      if (education) {
        const { _id } = education;
        const educationId = _id;
        const updatedValues = { educationId, ...values };
        console.log(updatedValues);
        dispatch(
          actions.updateQualification(
            config.updateQualification,
            updatedValues,
          ),
        );
      } else {
        dispatch(
          actions.createQualification(config.createQualification, values),
        );
      }
    } catch (error) {
      console.log(error);
    }
    handleCancel();
    setEducation(null);
  };

  const handleView = (education) => {
    setCurrenteducation(education);
    setIsViewModalVisible(true);
  };

  const handleEdit = (education) => {
    const educationData = {
      ...education,
      education: education?.education,
    };
    setEducation(educationData);
    setIsModalVisible(true);
    form.setFieldsValue(educationData);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setEducation(null);
    setCurrenteducation(null);
    form.resetFields();
  };
  const handleDelete = (id, is_active) => {
    dispatch(
      actions.deleteQualification(
        `${config.deleteQualification}?educationId=${id}&is_active=${!is_active}`,
      ),
    );
  };
  const columns = [
    {
      title: 'Qualification',
      dataIndex: 'education',
      key: 'Qualification',
      sorter: (a, b) => a.education - b.education,
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <Popconfirm
          title={`Are you sure you want to ${
            record.is_active ? 'deactivate' : 'activate'
          } this year?`}
          onConfirm={() => handleDelete(record._id, record.is_active)}
          okText="Yes"
          cancelText="No"
        >
          <Tag
            color={record.is_active ? 'green' : 'red'}
            style={{ cursor: 'pointer' }} // Adds a pointer cursor to indicate it's clickable
          >
            {record.is_active ? 'Active' : 'Inactive'}
          </Tag>
        </Popconfirm>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Add education
        </Title>
        <Space>
          <Input.Search
            placeholder="Search Year"
            enterButton
            className="search-input"
          />
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add education
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data?.qualifications}
        rowKey="_id"
        className="table-container"
      />

      <Modal
        title={education ? 'Edit Education' : 'Add Education'}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form form={form} onFinish={handleOk} layout="vertical">
          <Form.Item
            name="education"
            label="Education"
            rules={[{ required: true, message: 'Please input the education!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {education ? 'Save Changes' : 'Add education'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="View Year Details"
        visible={isViewModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <p>
          <strong>Education:</strong> {currenteducation?.education}
        </p>
        <p>
          <strong>Status:</strong>{' '}
          {currenteducation?.is_active ? 'Active' : 'Inactive'}
        </p>
      </Modal>
    </>
  );
};

export default Education;
