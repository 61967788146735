import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createCourseApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const updateCourseApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

const deleteCourseApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};

const getCourseListApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const sortCourseListApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
export function* sortCourseListSaga() {
  yield takeEvery(actions.SORT_COURSE_LIST_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(sortCourseListApi, payload);
      if (res) {
        yield put({
          type: actions.SORT_COURSE_LIST_SUCCESS,
          data: res.data,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.SORT_COURSE_LIST_ERROR,
          errorMessage: 'Failed to sort countries. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.SORT_COURSE_LIST_ERROR,
        errorMessage:
          'Something went wrong during country sorting. Please try again.',
      });
    }
  });
}
export function* createCourseSaga() {
  yield takeEvery(actions.CREATE_COURSE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createCourseApi, payload);
      if (res) {
        yield put({
          type: actions.CREATE_COURSE_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.CREATE_COURSE_ERROR,
          errorMessage: 'Failed to create course. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_COURSE_ERROR,
        errorMessage:
          'Something went wrong during course creation. Please try again.',
      });
    }
  });
}

export function* updateCourseSaga() {
  yield takeEvery(actions.UPDATE_COURSE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateCourseApi, payload);
      if (res) {
        yield put({
          type: actions.UPDATE_COURSE_SUCCESS,
          successMessage: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.UPDATE_COURSE_ERROR,
          errorMessage: 'Failed to update course. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_COURSE_ERROR,
        errorMessage:
          'Something went wrong during course update. Please try again.',
      });
    }
  });
}

export function* deleteCourseSaga() {
  yield takeEvery(actions.DELETE_COURSE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteCourseApi, payload);
      if (res) {
        yield put({
          type: actions.DELETE_COURSE_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.DELETE_COURSE_ERROR,
          errorMessage: 'Failed to delete course. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_COURSE_ERROR,
        errorMessage:
          'Something went wrong during course deletion. Please try again.',
        status_code: error.response ? error.response.status : 500,
      });
    }
  });
}

export function* getCourseListSaga() {
  yield takeEvery(actions.GET_COURSE_LIST_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getCourseListApi, payload);
      if (res) {
        yield put({
          type: actions.GET_COURSE_LIST_SUCCESS,
          data: res.data,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.GET_COURSE_LIST_ERROR,
          errorMessage: 'Failed to fetch courses. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_COURSE_LIST_ERROR,
        errorMessage:
          'Something went wrong while fetching courses. Please try again.',
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createCourseSaga),
    fork(updateCourseSaga),
    fork(deleteCourseSaga),
    fork(getCourseListSaga),
    fork(sortCourseListSaga),
  ]);
}
