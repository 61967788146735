const actions = {
  CREATEYEAR_OF_PASS_REQUEST: 'CREATEYEAR_OF_PASS_REQUEST',
  CREATEYEAR_OF_PASS_REQUEST_SUCCESS: 'CREATEYEAR_OF_PASS_REQUEST_SUCCESS',
  CREATEYEAR_OF_PASS_REQUEST_ERROR: 'CREATEYEAR_OF_PASS_REQUEST_ERROR',
  GET_YEAR_OF_PASS_REQUEST: 'GET_YEAR_OF_PASS_REQUEST',
  GET_YEAR_OF_PASS_SUCCESS: 'GET_YEAR_OF_PASS_SUCCESS',
  GET_YEAR_OF_PASS_ERROR: 'GET_YEAR_OF_PASS_ERROR',
  UPDATE_YEAR_OF_PASS: 'UPDATE_YEAR_OF_PASS',
  UPDATE_YEAR_OF_PASS_SUCCESS: 'UPDATE_YEAR_OF_PASS_SUCCESS',
  UPDATE_YEAR_OF_PASS_ERROR: 'UPDATE_YEAR_OF_PASS_ERROR',
  DELETE_YEAR_OF_PASS_REQUEST: 'DELETE_YEAR_OF_PASS_REQUEST',
  DELETE_YEAR_OF_PASS_SUCCESS: 'DELETE_YEAR_OF_PASS_SUCCESS',
  DELETE_YEAR_OF_PASS_ERROR: 'DELETE_YEAR_OF_PASS_ERROR',
  SORT_REQUEST: 'SORT_REQUEST',
  SORT_SUCCESS: 'SORT_SUCCESS',
  SORT_ERROR: 'SORT_ERROR',
  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',
  createYearofpass: (url, body) => ({
    type: actions.CREATEYEAR_OF_PASS_REQUEST,
    payload: { url, body },
  }),
  getcreateYearofpass: (url) => ({
    type: actions.GET_YEAR_OF_PASS_REQUEST,
    payload: { url },
  }),
  updateYearofpass: (url, body) => ({
    type: actions.UPDATE_YEAR_OF_PASS,
    payload: { url, body },
  }),
  deleteYearofpass: (url) => ({
    type: actions.DELETE_YEAR_OF_PASS_REQUEST,
    payload: { url },
  }),
  sortYearofpass: (url, body) => ({
    type: actions.SORT_REQUEST,
    payload: { url, body },
  }),
  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),
};

export default actions;
