// import React, { useCallback, useEffect, useState } from 'react';
// import {
//   Button,
//   Table,
//   Modal,
//   Form,
//   Input,
//   Space,
//   Popconfirm,
//   Typography,
//   Card,
//   Tag,
//   message,
// } from 'antd';
// import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
// import '../Screens/Coursecat.css';
// import { useDispatch, useSelector } from 'react-redux';
// import actions from '../../Redux/catagory/actions';
// import config from '../../Services/siteConfig';

// const { Title } = Typography;

// const CategoryManagement = () => {
//   const dispatch = useDispatch();

//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [editingCategory, setEditingCategory] = useState(null);

//   const { categories, successMessage, errorMessage, actionType } = useSelector(
//     (state) => {
//       return state.CategoryReducer;
//     },
//   );

//   const getCategoryList = useCallback(() => {
//     try {
//       dispatch(
//         actions.getCategoryList(`${config.getCategory}?page=${1}&limit=${20}`),
//       );
//     } catch (error) {
//       console.log(error);
//     }
//   }, [dispatch]);
//   useEffect(() => {
//     getCategoryList();
//   }, [getCategoryList]);
//   const columns = [
//     {
//       title: 'Category Name',
//       dataIndex: 'category_name',
//       key: 'categoryName',
//       sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
//     },
//     // {
//     //   title: 'Category Code',
//     //   dataIndex: 'categoryCode',
//     //   key: 'categoryCode',
//     //   sorter: (a, b) => a.categoryCode.localeCompare(b.categoryCode),
//     // },
//     {
//       title: 'Category Description',
//       dataIndex: 'description',
//       key: 'categoryDescription',
//     },
//     {
//       title: 'Status',
//       key: 'status',
//       render: (_, record) => (
//         <Tag color={record.is_active ? 'green' : 'red'}>
//           {record.is_active ? 'Active' : 'Inactive'}
//         </Tag>
//       ),
//     },
//     {
//       title: 'Actions',
//       key: 'actions',
//       render: (_, record) => (
//         <Space size="middle">
//           <Button
//             icon={<EyeOutlined />}
//             onClick={() => handleView(record)}
//             className="view-button"
//           />
//           <Button
//             icon={<EditOutlined />}
//             onClick={() => handleEdit(record)}
//             className="edit-button"
//           />
//           {/* <Popconfirm
//             title="Are you sure you want to delete this category?"
//             onConfirm={() => handleDelete(record.id)}
//           >
//             <Button icon={<DeleteOutlined />} className="delete-button" />
//           </Popconfirm> */}
//         </Space>
//       ),
//     },
//   ];

//   const handleView = (record) => {
//     Modal.info({
//       title: 'Category Details',
//       content: (
//         <Card
//           title={record.category_name}
//           bordered={false}
//           style={{ width: '100%', maxWidth: '600px' }}
//         >
//           {/* <p>
//             <strong>Category Code:</strong> {record?.categoryCode}
//           </p> */}
//           <p>
//             <strong>Category Description:</strong> {record.description}
//           </p>
//         </Card>
//       ),
//       width: 600, // Adjust the width as needed
//       centered: true, // Center the modal vertically
//     });
//   };

//   const handleEdit = (record) => {
//     setEditingCategory(record);
//     setIsModalVisible(true);
//   };

//   // const handleDelete = (id) => {
//   //   setCategories(categories.filter((category) => category.id !== id));
//   // };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//     setEditingCategory(null);
//   };

//   const handleOk = (values) => {
//     handleCancel();
//     const { categoryName, categoryCode, categoryDescription } = values;

//     try {
//       dispatch(actions.createCategory(config.createCategory, values));
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleSearch = (value) => {
//     // const lowercasedValue = value.toLowerCase();
//     // setFilteredCategories(
//     //   categories?.filter(
//     //     (category) =>
//     //       category.categoryName.toLowerCase().includes(lowercasedValue) ||
//     //       category.categoryCode.toLowerCase().includes(lowercasedValue) ||
//     //       category.categoryDescription.toLowerCase().includes(lowercasedValue),
//     //   ),
//     // );
//   };

//   const CategoryModal = ({ category, visible, onCancel }) => (
//     <Modal
//       title={category ? 'Edit Category' : 'Add New Category'}
//       visible={visible}
//       footer={null}
//       onCancel={onCancel}
//     >
//       <Form initialValues={category} onFinish={handleOk} layout="vertical">
//         <Form.Item
//           name="category_name"
//           label="Category Name"
//           rules={[
//             { required: true, message: 'Please enter the category name!' },
//             {
//               min: 2,
//               max: 50,
//               message: 'Invalid Category Name',
//             },
//           ]}
//         >
//           <Input />
//         </Form.Item>
//         {/* <Form.Item
//           name="categoryCode"
//           label="Category Code"
//           rules={[
//             { required: true, message: 'Please enter the category code!' },
//           ]}
//         >
//           <Input />
//         </Form.Item> */}
//         <Form.Item
//           name="description"
//           label="Category Description"
//           rules={[
//             {
//               required: true,
//               message: 'Please enter the category description!',
//             },
//             {
//               min: 3,
//               max: 500,
//               message: 'Invalid Description!',
//             },
//           ]}
//         >
//           <Input.TextArea rows={4} />
//         </Form.Item>
//         <Form.Item>
//           <Button type="primary" htmlType="submit">
//             {category ? 'Update Category' : 'Add Category'}
//           </Button>
//         </Form.Item>
//       </Form>
//     </Modal>
//   );

//   return (
//     <>
//       <div className="page-title">
//         <Title level={2} className="tittle-name">
//           Category List
//         </Title>
//         <Space>
//           <Input.Search
//             placeholder="Search categories"
//             onSearch={handleSearch}
//             enterButton
//             onChange={(e) => handleSearch(e.target.value)}
//             className="search-input"
//           />
//           <Button
//             type="primary"
//             onClick={() => {
//               setEditingCategory(null); // Set to null for new category
//               setIsModalVisible(true);
//             }}
//             className="add-button"
//           >
//             Add New Category
//           </Button>
//         </Space>
//       </div>
//       <Table
//         columns={columns}
//         dataSource={categories}
//         rowKey="id"
//         className="table-container"
//       />
//       <CategoryModal
//         category={editingCategory}
//         visible={isModalVisible}
//         onCancel={handleCancel}
//       />
//     </>
//   );
// };

// export default CategoryManagement;
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Space,
  Popconfirm,
  Typography,
  Card,
  Tag,
  message,
} from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../Redux/catagory/actions';
import config from '../../Services/siteConfig';
import '../Screens/Coursecat.css';
const { Title } = Typography;

const CategoryManagement = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const { categories, successMessage, errorMessage, actionType } = useSelector(
    (state) => state.CategoryReducer,
  );

  const getCategoryList = useCallback(() => {
    dispatch(actions.getCategoryList(`${config.getCategory}?page=1&limit=20`));
  }, [dispatch]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  useEffect(() => {
    if (
      actionType === 'CREATE_CATEGORY_SUCCESS' ||
      actionType === 'UPDATE_CATEGORY_SUCCESS' ||
      actionType === 'DELETE_CATEGORY_SUCCESS'
    ) {
      message.success(successMessage);
      getCategoryList();
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage, dispatch, getCategoryList]);

  const handleEdit = (category) => {
    setEditingCategory(category);
    setIsModalVisible(true);
  };

  const handleDelete = (id, is_active) => {
    dispatch(
      actions.deleteCategory(
        `${config.deleteCategory}?id=${id}&is_active=${!is_active}`,
      ),
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingCategory(null);
  };

  const handleOk = (values) => {
    if (editingCategory) {
      const { _id } = editingCategory;
      const updatedValues = { id: _id, ...values };
      dispatch(actions.updateCategory(config.updateCategory, updatedValues));
    } else {
      dispatch(actions.createCategory(config.createCategory, values));
    }
    handleCancel();
  };

  const handleView = (record) => {
    Modal.info({
      title: 'Category Details',
      content: (
        <Card title={record.category_name} bordered={false}>
          <p>
            <strong>Category Description:</strong> {record.description}
          </p>
        </Card>
      ),
      width: 600,
      centered: true,
    });
  };

  const columns = [
    {
      title: 'Category Name',
      dataIndex: 'category_name',
      key: 'categoryName',
      sorter: (a, b) => a.category_name.localeCompare(b.category_name),
    },
    {
      title: 'Category Description',
      dataIndex: 'description',
      key: 'categoryDescription',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <Popconfirm
          title={`Are you sure you want to ${
            record.is_active ? 'deactivate' : 'activate'
          } this category?`}
          onConfirm={() => handleDelete(record._id, record.is_active)}
          okText="Yes"
          cancelText="No"
        >
          <Tag color={record.is_active ? 'green' : 'red'}>
            {record.is_active ? 'Active' : 'Inactive'}
          </Tag>
        </Popconfirm>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<EyeOutlined />} onClick={() => handleView(record)} />
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
        </Space>
      ),
    },
  ];

  const CategoryModal = ({ category, visible, onCancel }) => (
    <Modal
      title={category ? 'Edit Category' : 'Add New Category'}
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <Form initialValues={category} onFinish={handleOk} layout="vertical">
        <Form.Item
          name="category_name"
          label="Category Name"
          rules={[
            { required: true, message: 'Please enter the category name!' },
            { min: 2, max: 50, message: 'Invalid Category Name' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category_code"
          label="Category Code"
          rules={[
            { required: true, message: 'Please enter the category code!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Category Description"
          rules={[
            {
              required: true,
              message: 'Please enter the category description!',
            },
            { min: 3, max: 500, message: 'Invalid Description!' },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {category ? 'Update Category' : 'Add Category'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Category List
        </Title>
        <Space>
          <Input.Search
            placeholder="Search categories"
            // onSearch={handleSearch}
            enterButton
            className="search-input"
          />
          <Button
            type="primary"
            onClick={() => {
              setEditingCategory(null);
              setIsModalVisible(true);
            }}
            className="add-button"
          >
            Add New Category
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={categories}
        rowKey="_id"
        className="table-container"
      />
      <CategoryModal
        category={editingCategory}
        visible={isModalVisible}
        onCancel={handleCancel}
      />
    </>
  );
};

export default CategoryManagement;
