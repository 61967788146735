import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Typography,
  Tag,
  message,
  Popconfirm,
} from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Redux/relationship/actions';
import config from '../../../Services/siteConfig';

const { Title } = Typography;

const Relationships = () => {
  const dispatch = useDispatch();
  const [relationship, setRelationship] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentRelationship, setCurrentRelationship] = useState(null);
  const [form] = Form.useForm();
  const { data, successMessage, errorMessage, actionType } = useSelector(
    (state) => state.RelationshipReducer,
  );

  const getRelationshipList = useCallback(() => {
    try {
      dispatch(
        actions.getRelationType(`${config.Relationship}?page=1&limit=20`),
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getRelationshipList();
  }, [getRelationshipList]);

  useEffect(() => {
    if (
      actionType === 'CREATE_RELATIONSHIP_SUCCESS' ||
      actionType === 'UPDATE_RELATIONSHIP_SUCCESS' ||
      actionType === 'DELETE_RELATIONSHIP_SUCCESS'
    ) {
      message.success(successMessage);
      getRelationshipList();
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage]);

  const handleOk = (values) => {
    try {
      if (relationship) {
        const { _id } = relationship;
        const relationship_typeId = _id;
        const updatedValues = { relationship_typeId, ...values };
        dispatch(
          actions.updateRelationType(config.Relationship, updatedValues),
        );
      } else {
        dispatch(actions.createRelationType(config.Relationship, values));
      }
    } catch (error) {
      console.log(error);
    }
    handleCancel();
    setRelationship(null);
  };

  const handleView = (relationship) => {
    setCurrentRelationship(relationship);
    setIsViewModalVisible(true);
  };

  const handleEdit = (relationship) => {
    const relationshipData = {
      ...relationship,
      relationship_type: relationship?.relationship_type,
    };
    setRelationship(relationshipData);
    setIsModalVisible(true);
    form.setFieldsValue(relationshipData);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setRelationship(null);
    setCurrentRelationship(null);
    form.resetFields();
  };

  const handleDelete = (id, is_active) => {
    dispatch(
      actions.deleteRelationType(
        `${config.Relationship}?relationship_typeId=${id}&is_active=${!is_active}`,
      ),
    );
  };

  const columns = [
    {
      title: 'Relationship Type',
      dataIndex: 'relationship_type',
      key: 'relationshipType',
      sorter: (a, b) => a.relationship_type.localeCompare(b.relationship_type),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <Popconfirm
          title={`Are you sure you want to ${
            record.is_active ? 'deactivate' : 'activate'
          } this relationship type?`}
          onConfirm={() => handleDelete(record._id, record.is_active)}
          okText="Yes"
          cancelText="No"
        >
          <Tag
            color={record.is_active ? 'green' : 'red'}
            style={{ cursor: 'pointer' }}
          >
            {record.is_active ? 'Active' : 'Inactive'}
          </Tag>
        </Popconfirm>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Relationship Types
        </Title>
        <Space>
          <Input.Search
            placeholder="Search Relationship Types"
            enterButton
            className="search-input"
          />
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add Relationship Type
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="_id"
        className="table-container"
      />

      <Modal
        title={
          relationship ? 'Edit Relationship Type' : 'Add Relationship Type'
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form form={form} onFinish={handleOk} layout="vertical">
          <Form.Item
            name="relationship_type"
            label="Relationship Type"
            rules={[
              {
                required: true,
                message: 'Please input the relationship type!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {relationship ? 'Save Changes' : 'Add Relationship Type'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="View Relationship Type"
        visible={isViewModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <p>
          <strong>Relationship Type:</strong>{' '}
          {currentRelationship?.relationship_type}
        </p>
        <p>
          <strong>Status:</strong>{' '}
          {currentRelationship?.is_active ? 'Active' : 'Inactive'}
        </p>
      </Modal>
    </>
  );
};

export default Relationships;
