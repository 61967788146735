import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Typography,
  Tag,
  message,
  Popconfirm,
} from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Redux/jobtitles/actions';
import config from '../../../Services/siteConfig';

const { Title } = Typography;

const Jobtitles = () => {
  const dispatch = useDispatch();
  const [jobtitle, setJobtitle] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentJobtitle, setCurrentJobtitle] = useState(null);
  const [form] = Form.useForm();

  const { data, successMessage, errorMessage, actionType } = useSelector(
    (state) => state.JobtitlesReducer,
  );

  const getJobtitlesList = useCallback(() => {
    try {
      dispatch(actions.getJobtitle(`${config.jobtitle}?page=1&limit=20`));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getJobtitlesList();
  }, [getJobtitlesList]);

  useEffect(() => {
    if (
      actionType === 'CREATE_JOBTITLE_SUCCESS' ||
      actionType === 'UPDATE_JOBTITLE_SUCCESS' ||
      actionType === 'DELETE_JOBTITLE_SUCCESS'
    ) {
      message.success(successMessage);
      dispatch(actions.resetActionType());
      getJobtitlesList();
    }
  }, [actionType, successMessage, getJobtitlesList, dispatch]);

  const handleOk = (values) => {
    try {
      if (jobtitle) {
        const { _id } = jobtitle;
        const jobtitleId = _id;
        const updatedValues = { jobtitleId, ...values };
        dispatch(actions.updateJobtitle(config.jobtitle, updatedValues));
      } else {
        dispatch(actions.createJobtitle(config.jobtitle, values));
      }
    } catch (error) {
      console.log(error);
    }
    handleCancel();
  };

  const handleView = (jobtitle) => {
    setCurrentJobtitle(jobtitle);
    setIsViewModalVisible(true);
  };

  const handleEdit = (jobtitle) => {
    setJobtitle(jobtitle);
    setIsModalVisible(true);
    form.setFieldsValue(jobtitle);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setJobtitle(null);
    setCurrentJobtitle(null);
    form.resetFields();
  };

  const handleDelete = (id, isActive) => {
    dispatch(
      actions.deleteJobtitle(
        `${config.jobtitle}?jobtitleId=${id}&is_active=${!isActive}`,
      ),
    );
  };

  const columns = [
    {
      title: 'Job Title',
      dataIndex: 'jobtitle',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <Popconfirm
          title={`Are you sure you want to ${
            record.is_active ? 'deactivate' : 'activate'
          } this job title?`}
          onConfirm={() => handleDelete(record._id, record.is_active)}
          okText="Yes"
          cancelText="No"
        >
          <Tag
            color={record.is_active ? 'green' : 'red'}
            style={{ cursor: 'pointer' }}
          >
            {record.is_active ? 'Active' : 'Inactive'}
          </Tag>
        </Popconfirm>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Job Titles
        </Title>
        <Space>
          <Input.Search
            placeholder="Search Job Titles"
            enterButton
            className="search-input"
          />
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add Job Title
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data?.jobLevels}
        rowKey="id"
        className="table-container"
      />
      <Modal
        title={jobtitle ? 'Edit Job Title' : 'Add Job Title'}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form form={form} onFinish={handleOk} layout="vertical">
          <Form.Item
            name="jobtitle"
            label="Job Title"
            rules={[{ required: true, message: 'Please input the job title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {jobtitle ? 'Save Changes' : 'Add Job Title'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="View Job Title Details"
        visible={isViewModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <p>
          <strong>Title:</strong> {currentJobtitle?.jobtitle}
        </p>
        <p>
          <strong>Status:</strong>{' '}
          {currentJobtitle?.isActive ? 'Active' : 'Inactive'}
        </p>
      </Modal>
    </>
  );
};

export default Jobtitles;
