const actions = {
  CREATE_RELATIONSHIP_REQUEST: 'CREATE_RELATIONSHIP_REQUEST',
  CREATE_RELATIONSHIP_SUCCESS: 'CREATE_RELATIONSHIP_SUCCESS',
  CREATE_RELATIONSHIP_ERROR: 'CREATE_RELATIONSHIP_ERROR',
  GET_RELATIONSHIP_REQUEST: 'GET_RELATIONSHIP_REQUEST',
  GET_RELATIONSHIP_SUCCESS: 'GET_RELATIONSHIP_SUCCESS',
  GET_RELATIONSHIP_ERROR: 'GET_RELATIONSHIP_ERROR',
  UPDATE_RELATIONSHIP_REQUEST: ']UPDATE_RELATIONSHIP_REQUEST',
  UPDATE_RELATIONSHIP_SUCCESS: 'UPDATE_RELATIONSHIP_SUCCESS',
  UPDATE_RELATIONSHIP_ERROR: 'UPDATE_RELATIONSHIP_ERROR',
  DELETE_RELATIONSHIP_REQUEST: 'DELETE_RELATIONSHIP_REQUEST',
  DELETE_RELATIONSHIP_SUCCESS: 'DELETE_RELATIONSHIP_SUCCESS',
  DELETE_RELATIONSHIP_ERROR: 'DELETE_RELATIONSHIP_ERROR',
  SORT_REQUEST: 'SORT_REQUEST',
  SORT_SUCCESS: 'SORT_SUCCESS',
  SORT_ERROR: 'SORT_ERROR',
  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',
  createRelationType: (url, body) => ({
    type: actions.CREATE_RELATIONSHIP_REQUEST,
    payload: { url, body },
  }),
  getRelationType: (url) => ({
    type: actions.GET_RELATIONSHIP_REQUEST,
    payload: { url },
  }),
  updateRelationType: (url, body) => ({
    type: actions.UPDATE_RELATIONSHIP_REQUEST,
    payload: { url, body },
  }),
  deleteRelationType: (url) => ({
    type: actions.DELETE_RELATIONSHIP_REQUEST,
    payload: { url },
  }),
  sortYearofpass: (url, body) => ({
    type: actions.SORT_REQUEST,
    payload: { url, body },
  }),
  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),
};

export default actions;
