import { message } from 'antd';
import axios from 'axios';
const AxiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
// AxiosInterceptor.interceptors.request.use((config) => {
//   config.headers.tenant = !config.tenant ? process.env.REACT_APP_TENANT : config.tenant;
//   return config;
// });
AxiosInterceptor.interceptors.response.use(
  (res) => {
    return res?.data;
  },
  (error) => {
    const token = localStorage.jwtToken;
    if (error?.response?.data?.message) {
      message.error(error.response.data.message);
    } else if (
      error?.response?.data?.user_msg === 'inavlid token' ||
      !!token === false
    ) {
      localStorage.clear();
      console.clear();
      document.location.href = '/';
    }
    return error;
  },
);

export default AxiosInterceptor;
