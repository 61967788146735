import React from 'react';
import { Card, Form, Input, Button, Typography } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './login.css';

const { Title } = Typography;

const Login = () => {
  const onFinish = (values) => {
    // Handle form submission
    console.log('Success:', values);
  };

  return (
    <div className="outer-container">
      <div className="inner-container">
        <Card className="image-card" bordered={false} />
        <Card className="card" bordered={false}>
          <div>
            <Title level={2} className="title">
              Welcome to Backoffice
            </Title>

            <Form
              name="login"
              onFinish={onFinish}
              layout="vertical"
              className="form"
            >
              <Form.Item
                name="contact"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email or mobile number!',
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined style={{ color: '#6e45e2' }} />}
                  placeholder="Email or Mobile Number"
                  size="large"
                  className="input"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please enter your password!' },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: '#6e45e2' }} />}
                  placeholder="Password"
                  size="large"
                  className="input"
                />
              </Form.Item>
              <Form.Item>
                <Link to="/dashboard">
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    className="button"
                  >
                    Log in
                  </Button>
                </Link>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Login;
