const actions = {
  CREATESUBJECT_REQUEST: 'CREATESUBJECT_REQUEST',
  CREATESUBJECT_SUCCESS: 'CREATESUBJECT_SUCCESS',
  CREATESUBJECT_ERROR: 'CREATESUBJECT_ERROR',
  GET_SUBJECT_REQUEST: 'GET_SUBJECT_REQUEST',
  GET_SUBJECT_SUCCESS: 'GET_SUBJECT_SUCCESS',
  GET_SUBJECT_ERROR: 'GET_SUBJECT_ERROR',
  UPDATE_SUBJECT_REQUEST: 'UPDATE_SUBJECT_REQUEST',
  UPDATE_SUBJECT_SUCCESS: 'UPDATE_SUBJECT_SUCCESS',
  UPDATE_SUBJECT_ERROR: 'UPDATE_SUBJECT_ERROR',
  DELETE_SUBJECT_REQUEST: 'DELETE_SUBJECT_REQUEST',
  DELETE_SUBJECT_SUCCESS: 'DELETE_SUBJECT_SUCCESS',
  DELETE_SUBJECT_ERROR: 'DELETE_SUBJECT_ERROR',
  SORT_SUBJECT_REQUEST: 'SORT_SUBJECT_REQUEST',
  SORT_SUBJECT_SUCCESS: 'SORT_SUBJECT_SUCCESS',
  SORT_SUBJECT_ERROR: 'SORT_SUBJECT_ERROR',
  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',

  createSubject: (url, body) => ({
    type: actions.CREATESUBJECT_REQUEST,
    payload: { url, body },
  }),
  getSubject: (url) => ({
    type: actions.GET_SUBJECT_REQUEST,
    payload: { url },
  }),
  updateSubject: (url, body) => ({
    type: actions.UPDATE_SUBJECT_REQUEST,
    payload: { url, body },
  }),
  deleteSubject: (url) => ({
    type: actions.DELETE_SUBJECT_REQUEST,
    payload: { url },
  }),
  sortSubject: (url, body) => ({
    type: actions.SORT_SUBJECT_REQUEST,
    payload: { url, body },
  }),
  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),
};

export default actions;
