import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createRelationshipApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const getRelationsshipApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const updateRelation = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};
const deleteApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};

export function* createRelationshipSaga() {
  yield takeEvery(actions.CREATE_RELATIONSHIP_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createRelationshipApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.CREATE_RELATIONSHIP_SUCCESS,
          successMessage: res?.status,
        });
      } else {
        yield put({
          type: actions.CREATE_RELATIONSHIP_ERROR,
          error: 'Failed to create category. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_RELATIONSHIP_ERROR,
        error:
          'Something went wrong during category creation. Please try again.',
      });
    }
  });
}

export function* getRelationshipSaga() {
  yield takeEvery(actions.GET_RELATIONSHIP_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getRelationsshipApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.GET_RELATIONSHIP_SUCCESS,
          data: res?.data,
        });
      } else {
        yield put({
          type: actions.GET_RELATIONSHIP_ERROR,
          error: 'Failed to fetch categories. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_RELATIONSHIP_ERROR,
        error:
          'Something went wrong while fetching categories. Please try again.',
      });
    }
  });
}
export function* updateRealationshipSaga() {
  yield takeEvery(actions.UPDATE_RELATIONSHIP_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateRelation, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.UPDATE_RELATIONSHIP_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.UPDATE_RELATIONSHIP_ERROR,
          error: 'Failed to fetch categories. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_RELATIONSHIP_ERROR,
        error:
          'Something went wrong while fetching categories. Please try again.',
      });
    }
  });
}
export function* deleteRelationshipSaga() {
  yield takeEvery(actions.DELETE_RELATIONSHIP_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.DELETE_RELATIONSHIP_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.DELETE_RELATIONSHIP_ERROR,
          error: 'Failed to fetch categories. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_RELATIONSHIP_ERROR,
        error:
          'Something went wrong while fetching categories. Please try again.',
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createRelationshipSaga),
    fork(getRelationshipSaga),
    fork(updateRealationshipSaga),
    fork(deleteRelationshipSaga),
  ]);
}
