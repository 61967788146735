import { all } from 'redux-saga/effects';
import Categorysaga from '../Redux/catagory/saga';
import Relationsaga from '../Redux/relationship/saga';
import yearofpasssaga from '../Redux/Yearofpass/saga';
import Education from '../Redux/Education/saga';
import JobtitleSaga from '../Redux/jobtitles/saga';
import ModuleSaga from '../Redux/module/saga';
import createGradeSaga from '../Redux/Grades/saga';
import createStateSaga from '../Redux/States/saga';
import createCountrySaga from '../Redux/Country/saga';
import createCourseSaga from '../Redux/Courses/saga';
export default function* rootSaga() {
  yield all([
    Categorysaga(),
    Relationsaga(),
    yearofpasssaga(),
    Education(),
    JobtitleSaga(),
    ModuleSaga(),
    Categorysaga(),
    createGradeSaga(),
    createStateSaga(),
    createCountrySaga(),
    createCourseSaga(),
  ]);
}
