import actions from './actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  successMessage: '',
  errorMessage: '',
  actionType: '',
  status_code: 0,
  countries: {},
});

export default function CountryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_COUNTRY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'CREATE_COUNTRY_REQUEST',
      });
    case actions.CREATE_COUNTRY_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_COUNTRY_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.CREATE_COUNTRY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_COUNTRY_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.UPDATE_COUNTRY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'UPDATE_COUNTRY_REQUEST',
      });
    case actions.UPDATE_COUNTRY_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_COUNTRY_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.UPDATE_COUNTRY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_COUNTRY_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.DELETE_COUNTRY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'DELETE_COUNTRY_REQUEST',
      });
    case actions.DELETE_COUNTRY_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_COUNTRY_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.DELETE_COUNTRY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_COUNTRY_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.GET_COUNTRY_LIST_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'GET_COUNTRY_LIST_REQUEST',
      });
    case actions.GET_COUNTRY_LIST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_COUNTRY_LIST_SUCCESS',
        countries: action.data,
        status_code: action.status_code,
        successMessage: action.message,
      });
    case actions.GET_COUNTRY_LIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_COUNTRY_LIST_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.SORT_COUNTRY_LIST_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'SORT_COUNTRY_LIST_REQUEST',
      });
    case actions.SORT_COUNTRY_LIST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_COUNTRY_LIST_SUCCESS',
        countries: action.data,
        status_code: action.status_code,
        successMessage: action.message,
      });
    case actions.SORT_COUNTRY_LIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_COUNTRY_LIST_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: '',
        successMessage: '',
        errorMessage: '',
        status_code: 0,
      });
    default:
      return state;
  }
}
