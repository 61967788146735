import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createGradeApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const updateGradeApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

const deleteGradeApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};

const getGradeListApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* createGradeSaga() {
  yield takeEvery(actions.CREATE_GRADE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createGradeApi, payload);
      if (res) {
        yield put({
          type: actions.CREATE_GRADE_SUCCESS,

          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.CREATE_GRADE_ERROR,
          errormessage: 'Failed to create grade. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_GRADE_ERROR,

        errormessage:
          'Something went wrong during grade creation. Please try again.',
      });
    }
  });
}

export function* updateGradeSaga() {
  yield takeEvery(actions.UPDATE_GRADE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateGradeApi, payload);
      if (res) {
        yield put({
          type: actions.UPDATE_GRADE_SUCCESS,

          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.UPDATE_GRADE_ERROR,

          errormessage: 'Failed to update grade. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_GRADE_ERROR,

        errormessage:
          'Something went wrong during grade update. Please try again.',
      });
    }
  });
}

export function* deleteGradeSaga() {
  yield takeEvery(actions.DELETE_GRADE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteGradeApi, payload);
      if (res) {
        yield put({
          type: actions.DELETE_GRADE_SUCCESS,

          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.DELETE_GRADE_ERROR,

          errormessage: 'Failed to delete grade. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_GRADE_ERROR,

        errormessage:
          'Something went wrong during grade deletion. Please try again.',
        status_code: error.response ? error.response.status : 500,
      });
    }
  });
}

export function* getGradeListSaga() {
  yield takeEvery(actions.GET_GRADE_LIST_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getGradeListApi, payload);
      if (res) {
        yield put({
          type: actions.GET_GRADE_LIST_SUCCESS,

          data: res.data,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.GET_GRADE_LIST_ERROR,

          errormessage: 'Failed to fetch grades. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_GRADE_LIST_ERROR,

        errormessage:
          'Something went wrong while fetching grades. Please try again.',
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createGradeSaga),
    fork(updateGradeSaga),
    fork(deleteGradeSaga),
    fork(getGradeListSaga),
  ]);
}
