import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = sessionStorage.getItem('verify');

  if (!!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return <Component {...rest} />;
};

export default PublicRoute;
