import actions from './actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  successMessage: '',
  errorMessage: '',
  actionType: '',
  status_code: 0,
  data: [],
});

export default function JobtitlesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_JOBTITLE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'CREATE_JOBTITLE_REQUEST',
      });
    case actions.CREATE_JOBTITLE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_JOBTITLE_SUCCESS',
        successMessage: action.successMessage,
      });
    case actions.CREATE_JOBTITLE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_JOBTITLE_ERROR',
        errorMessage: action.error,
      });
    case actions.GET_JOBTITLE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'GET_JOBTITLE_REQUEST',
      });
    case actions.GET_JOBTITLE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_JOBTITLE_SUCCESS',
        data: action.data,
      });
    case actions.GET_JOBTITLE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_JOBTITLE_ERROR',
        errorMessage: action.error,
      });
    case actions.UPDATE_JOBTITLE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'UPDATE_JOBTITLE_REQUEST',
      });
    case actions.UPDATE_JOBTITLE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_JOBTITLE_SUCCESS',
        successMessage: action.successMessage,
      });
    case actions.UPDATE_JOBTITLE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_JOBTITLE_ERROR',
        errorMessage: action.error,
      });
    case actions.DELETE_JOBTITLE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'DELETE_JOBTITLE_REQUEST',
      });
    case actions.DELETE_JOBTITLE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_JOBTITLE_SUCCESS',
        successMessage: action.successMessage,
      });
    case actions.DELETE_JOBTITLE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_JOBTITLE_ERROR',
        errorMessage: action.error,
      });
    case actions.SORT_JOBTITLE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'SORT_JOBTITLE_REQUEST',
      });
    case actions.SORT_JOBTITLE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_JOBTITLE_SUCCESS',
        data: action.data,
      });
    case actions.SORT_JOBTITLE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_JOBTITLE_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });

    case actions.RESET_JOBTITLE_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: '',
        successMessage: '',
        errorMessage: '',
        status_code: 0,
        data: [],
      });
    default:
      return state;
  }
}
