import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions'; // Adjust the path if necessary
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createyearofpassApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const getyearListApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const updateyearofpassApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};
const deleteyearApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};
export function* createYearofpassSaga() {
  yield takeEvery(actions.CREATEYEAR_OF_PASS_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createyearofpassApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.CREATEYEAR_OF_PASS_REQUEST_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.CREATEYEAR_OF_PASS_REQUEST_ERROR,
          error: 'Failed to create category. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATEYEAR_OF_PASS_REQUEST_ERROR,
        error:
          'Something went wrong during category creation. Please try again.',
      });
    }
  });
}

export function* getYearofpassListSaga() {
  yield takeEvery(actions.GET_YEAR_OF_PASS_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getyearListApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.GET_YEAR_OF_PASS_SUCCESS,
          data: res?.data,
        });
      } else {
        yield put({
          type: actions.GET_YEAR_OF_PASS_ERROR,
          error: 'Failed to fetch categories. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_YEAR_OF_PASS_ERROR,
        error:
          'Something went wrong while fetching categories. Please try again.',
      });
    }
  });
}
export function* updateYearofpassSaga() {
  yield takeEvery(actions.UPDATE_YEAR_OF_PASS, function* ({ payload }) {
    try {
      const res = yield call(updateyearofpassApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.UPDATE_YEAR_OF_PASS_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.UPDATE_YEAR_OF_PASS_ERROR,
          error: 'Failed to fetch categories. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_YEAR_OF_PASS_ERROR,
        error:
          'Something went wrong while fetching categories. Please try again.',
      });
    }
  });
}
export function* deleteYearofpassSaga() {
  yield takeEvery(actions.DELETE_YEAR_OF_PASS_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteyearApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.DELETE_YEAR_OF_PASS_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.DELETE_YEAR_OF_PASS_ERROR,
          error: 'Failed to fetch categories. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_YEAR_OF_PASS_ERROR,
        error:
          'Something went wrong while fetching categories. Please try again.',
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createYearofpassSaga),
    fork(getYearofpassListSaga),
    fork(updateYearofpassSaga),
    fork(deleteYearofpassSaga),
  ]);
}
