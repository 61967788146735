import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions'; // Adjust the path if necessary
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createJobtitleApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const getJobtitleApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const updateJobtitleApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

const deleteJobtitleApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};

export function* createJobtitleSaga() {
  yield takeEvery(actions.CREATE_JOBTITLE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createJobtitleApi, payload);
      if (res?.status_code === 1) {
        yield put({
          type: actions.CREATE_JOBTITLE_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.CREATE_JOBTITLE_ERROR,
          error: 'Failed to create job title. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_JOBTITLE_ERROR,
        error:
          'Something went wrong during job title creation. Please try again.',
      });
    }
  });
}

export function* getJobtitleSaga() {
  yield takeEvery(actions.GET_JOBTITLE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getJobtitleApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.GET_JOBTITLE_SUCCESS,
          data: res?.data,
        });
      } else {
        yield put({
          type: actions.GET_JOBTITLE_ERROR,
          error: 'Failed to fetch job titles. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_JOBTITLE_ERROR,
        error:
          'Something went wrong while fetching job titles. Please try again.',
      });
    }
  });
}

export function* updateJobtitleSaga() {
  yield takeEvery(actions.UPDATE_JOBTITLE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateJobtitleApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.UPDATE_JOBTITLE_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.UPDATE_JOBTITLE_ERROR,
          error: 'Failed to update job title. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_JOBTITLE_ERROR,
        error:
          'Something went wrong while updating job title. Please try again.',
      });
    }
  });
}

export function* deleteJobtitleSaga() {
  yield takeEvery(actions.DELETE_JOBTITLE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteJobtitleApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.DELETE_JOBTITLE_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.DELETE_JOBTITLE_ERROR,
          error: 'Failed to delete job title. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_JOBTITLE_ERROR,
        error:
          'Something went wrong while deleting job title. Please try again.',
      });
    }
  });
}

export default function* jobtitleSaga() {
  yield all([
    fork(createJobtitleSaga),
    fork(getJobtitleSaga),
    fork(updateJobtitleSaga),
    fork(deleteJobtitleSaga),
  ]);
}
