const actions = {
  CREATE_QUALIFICATION_REQUEST: 'CREATE_QUALIFICATION_REQUEST',
  CREATE_QUALIFICATION__SUCCESS: 'CREATE_QUALIFICATION__SUCCESS',
  CREATE_QUALIFICATION__ERROR: 'CREATE_QUALIFICATION__ERROR',
  GET_QUALIFICATION_REQUEST: 'GET_QUALIFICATION_REQUEST',
  GET_QUALIFICATION_SUCCESS: 'GET_QUALIFICATION_SUCCESS',
  GET_QUALIFICATION_ERROR: 'GET_QUALIFICATION_ERROR',
  UPDATE_QUALIFICATION_REQUEST: 'UPDATE_QUALIFICATION_REQUEST',
  UPDATE_QUALIFICATION_SUCCESS: 'UPDATE_QUALIFICATION_SUCCESS',
  UPDATE_QUALIFICATION_ERROR: 'UPDATE_QUALIFICATION_ERROR',
  DELETE_QUALIFICATION_REQUEST: 'DELETE_QUALIFICATION_REQUEST',
  DELETE_QUALIFICATION_SUCCESS: 'DELETE_QUALIFICATION_SUCCESS',
  DELETE_QUALIFICATION_ERROR: 'DELETE_QUALIFICATION_ERROR',
  SORT_REQUEST: 'SORT_REQUEST',
  SORT_SUCCESS: 'SORT_SUCCESS',
  SORT_ERROR: 'SORT_ERROR',
  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',
  createQualification: (url, body) => ({
    type: actions.CREATE_QUALIFICATION_REQUEST,
    payload: { url, body },
  }),
  getQualification: (url) => ({
    type: actions.GET_QUALIFICATION_REQUEST,
    payload: { url },
  }),
  updateQualification: (url, body) => ({
    type: actions.UPDATE_QUALIFICATION_REQUEST,
    payload: { url, body },
  }),
  deleteQualification: (url) => ({
    type: actions.DELETE_QUALIFICATION_REQUEST,
    payload: { url },
  }),
  sortQualification: (url, body) => ({
    type: actions.SORT_REQUEST,
    payload: { url, body },
  }),
  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),
};

export default actions;
