const actions = {
  // Country actions
  CREATE_COUNTRY_REQUEST: 'CREATE_COUNTRY_REQUEST',
  CREATE_COUNTRY_SUCCESS: 'CREATE_COUNTRY_SUCCESS',
  CREATE_COUNTRY_ERROR: 'CREATE_COUNTRY_ERROR',

  UPDATE_COUNTRY_REQUEST: 'UPDATE_COUNTRY_REQUEST',
  UPDATE_COUNTRY_SUCCESS: 'UPDATE_COUNTRY_SUCCESS',
  UPDATE_COUNTRY_ERROR: 'UPDATE_COUNTRY_ERROR',

  DELETE_COUNTRY_REQUEST: 'DELETE_COUNTRY_REQUEST',
  DELETE_COUNTRY_SUCCESS: 'DELETE_COUNTRY_SUCCESS',
  DELETE_COUNTRY_ERROR: 'DELETE_COUNTRY_ERROR',

  GET_COUNTRY_LIST_REQUEST: 'GET_COUNTRY_LIST_REQUEST',
  GET_COUNTRY_LIST_SUCCESS: 'GET_COUNTRY_LIST_SUCCESS',
  GET_COUNTRY_LIST_ERROR: 'GET_COUNTRY_LIST_ERROR',

  SORT_COUNTRY_LIST_REQUEST: 'SORT_COUNTRY_LIST_REQUEST',
  SORT_COUNTRY_LIST_SUCCESS: 'SORT_COUNTRY_LIST_SUCCESS',
  SORT_COUNTRY_LIST_ERROR: 'SORT_COUNTRY_LIST_ERROR',

  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',

  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),

  createCountry: (url, body) => ({
    type: actions.CREATE_COUNTRY_REQUEST,
    payload: { url, body },
  }),

  updateCountry: (url, body) => ({
    type: actions.UPDATE_COUNTRY_REQUEST,
    payload: { url, body },
  }),

  deleteCountry: (url) => ({
    type: actions.DELETE_COUNTRY_REQUEST,
    payload: { url },
  }),

  getCountryList: (url) => ({
    type: actions.GET_COUNTRY_LIST_REQUEST,
    payload: { url },
  }),

  sortCountryList: (url) => ({
    type: actions.SORT_COUNTRY_LIST_REQUEST,
    payload: { url },
  }),
};

export default actions;
