const actions = {
  // Reset Action
  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',

  // Create State
  CREATE_STATE_REQUEST: 'CREATE_STATE_REQUEST',
  CREATE_STATE_SUCCESS: 'CREATE_STATE_SUCCESS',
  CREATE_STATE_ERROR: 'CREATE_STATE_ERROR',

  // Get State List
  GET_STATE_LIST_REQUEST: 'GET_STATE_LIST_REQUEST',
  GET_STATE_LIST_SUCCESS: 'GET_STATE_LIST_SUCCESS',
  GET_STATE_LIST_ERROR: 'GET_STATE_LIST_ERROR',

  // Update State
  UPDATE_STATE_REQUEST: 'UPDATE_STATE_REQUEST',
  UPDATE_STATE_SUCCESS: 'UPDATE_STATE_SUCCESS',
  UPDATE_STATE_ERROR: 'UPDATE_STATE_ERROR',

  // Delete State
  DELETE_STATE_REQUEST: 'DELETE_STATE_REQUEST',
  DELETE_STATE_SUCCESS: 'DELETE_STATE_SUCCESS',
  DELETE_STATE_ERROR: 'DELETE_STATE_ERROR',

  SORT_REQUEST: 'SORT_REQUEST',
  SORT_SUCCESS: 'SORT_SUCCESS',
  SORT_ERROR: 'SORT_ERROR',

  // Action Creators
  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),

  createState: (url, body) => ({
    type: actions.CREATE_STATE_REQUEST,
    payload: { url, body },
  }),

  getStateList: (url) => ({
    type: actions.GET_STATE_LIST_REQUEST,
    payload: { url },
  }),

  updateState: (url, body) => ({
    type: actions.UPDATE_STATE_REQUEST,
    payload: { url, body },
  }),

  deleteState: (url) => ({
    type: actions.DELETE_STATE_REQUEST,
    payload: { url },
  }),

  sortState: (url) => ({
    type: actions.SORT_REQUEST,
    payload: { url },
  }),
};

export default actions;
