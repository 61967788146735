const actions = {
  CREATE_COURSE_REQUEST: 'CREATE_COURSE_REQUEST',
  CREATE_COURSE_SUCCESS: 'CREATE_COURSE_SUCCESS',
  CREATE_COURSE_ERROR: 'CREATE_COURSE_ERROR',

  UPDATE_COURSE_REQUEST: 'UPDATE_COURSE_REQUEST',
  UPDATE_COURSE_SUCCESS: 'UPDATE_COURSE_SUCCESS',
  UPDATE_COURSE_ERROR: 'UPDATE_COURSE_ERROR',

  DELETE_COURSE_REQUEST: 'DELETE_COURSE_REQUEST',
  DELETE_COURSE_SUCCESS: 'DELETE_COURSE_SUCCESS',
  DELETE_COURSE_ERROR: 'DELETE_COURSE_ERROR',

  GET_COURSE_LIST_REQUEST: 'GET_COURSE_LIST_REQUEST',
  GET_COURSE_LIST_SUCCESS: 'GET_COURSE_LIST_SUCCESS',
  GET_COURSE_LIST_ERROR: 'GET_COURSE_LIST_ERROR',

  SORT_COURSE_LIST_REQUEST: 'SORT_COURSE_LIST_REQUEST',
  SORT_COURSE_LIST_SUCCESS: 'SORT_COURSE_LIST_SUCCESS',
  SORT_COURSE_LIST_ERROR: 'SORT_COURSE_LIST_ERROR',

  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',

  createCourse: (url, body) => ({
    type: actions.CREATE_COURSE_REQUEST,
    payload: { url, body },
  }),

  updateCourse: (url, body) => ({
    type: actions.UPDATE_COURSE_REQUEST,
    payload: { url, body },
  }),

  deleteCourse: (url) => ({
    type: actions.DELETE_COURSE_REQUEST,
    payload: { url },
  }),

  getCourseList: (url) => ({
    type: actions.GET_COURSE_LIST_REQUEST,
    payload: { url },
  }),

  sortCourseList: (url) => ({
    type: actions.SORT_COURSE_LIST_REQUEST,
    payload: { url },
  }),

  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),
};

export default actions;
