const actions = {
  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',

  CREATE_GRADE_REQUEST: 'CREATE_GRADE_REQUEST',
  CREATE_GRADE_SUCCESS: 'CREATE_GRADE_SUCCESS',
  CREATE_GRADE_ERROR: 'CREATE_GRADE_ERROR',
  UPDATE_GRADE_REQUEST: 'UPDATE_GRADE_REQUEST',
  UPDATE_GRADE_SUCCESS: 'UPDATE_GRADE_SUCCESS',
  UPDATE_GRADE_ERROR: 'UPDATE_GRADE_ERROR',
  DELETE_GRADE_REQUEST: 'DELETE_GRADE_REQUEST',
  DELETE_GRADE_SUCCESS: 'DELETE_GRADE_SUCCESS',
  DELETE_GRADE_ERROR: 'DELETE_GRADE_ERROR',
  GET_GRADE_LIST_REQUEST: 'GET_GRADE_LIST_REQUEST',
  GET_GRADE_LIST_SUCCESS: 'GET_GRADE_LIST_SUCCESS',
  GET_GRADE_LIST_ERROR: 'GET_GRADE_LIST_ERROR',

  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),

  createGrade: (url, body) => ({
    type: actions.CREATE_GRADE_REQUEST,
    payload: { url, body },
  }),

  updateGrade: (url, body) => ({
    type: actions.UPDATE_GRADE_REQUEST,
    payload: { url, body },
  }),

  deleteGrade: (url) => ({
    type: actions.DELETE_GRADE_REQUEST,
    payload: { url },
  }),

  getGradeList: (url) => ({
    type: actions.GET_GRADE_LIST_REQUEST,
    payload: { url },
  }),
};

export default actions;
