import actions from './actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  successMessage: '',
  errorMessage: '',
  actionType: '',
  status_code: 0,
  courses: {},
});

export default function CourseReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_COURSE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'CREATE_COURSE_REQUEST',
      });
    case actions.CREATE_COURSE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_COURSE_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.CREATE_COURSE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_COURSE_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.UPDATE_COURSE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'UPDATE_COURSE_REQUEST',
      });
    case actions.UPDATE_COURSE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_COURSE_SUCCESS',
        successMessage: action.successMessage,
        status_code: action.status_code,
      });
    case actions.UPDATE_COURSE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_COURSE_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.DELETE_COURSE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'DELETE_COURSE_REQUEST',
      });
    case actions.DELETE_COURSE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_COURSE_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.DELETE_COURSE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_COURSE_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.GET_COURSE_LIST_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'GET_COURSE_LIST_REQUEST',
      });
    case actions.GET_COURSE_LIST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_COURSE_LIST_SUCCESS',
        courses: action.data,
        status_code: action.status_code,
        successMessage: action.message,
      });
    case actions.GET_COURSE_LIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_COURSE_LIST_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });

    case actions.SORT_COURSE_LIST_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'SORT_COURSE_LIST_REQUEST',
      });
    case actions.SORT_COURSE_LIST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_COURSE_LIST_SUCCESS',
        courses: action.data,
        status_code: action.status_code,
        successMessage: action.message,
      });
    case actions.SORT_COURSE_LIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_COURSE_LIST_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });

    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: '',
        successMessage: '',
        errorMessage: '',
        status_code: 0,
      });
    default:
      return state;
  }
}
