import React, { useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Popconfirm,
  Typography,
  DatePicker,
  Row,
  Col,
  Tabs,
  Tag,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CalendarOutlined,
  UserOutlined,
  DollarOutlined,
  ClockCircleOutlined,
  BookOutlined,
  TeamOutlined,
  ToolOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

const { Title } = Typography;
const { TabPane } = Tabs;

const Module = () => {
  const [courses, setCourses] = useState([
    {
      courseName: 'Introduction to React',
      description: 'A comprehensive guide to learning React from scratch.',
      duration: '6 weeks',
      startDate: '2024-09-01',
      endDate: '2024-10-15',
      instructor: 'John Doe',
      fee: '$200',
      category: 'Web Development',
      prerequisites: 'Basic knowledge of JavaScript',
      materials: 'Course slides, sample code',
    },
    {
      id: 2,
      courseName: 'Data Science Bootcamp',
      description:
        'Intensive program covering data analysis, machine learning, and visualization',
      duration: '4 Months',
      startDate: '2024-09-01T00:00:00.000Z',
      endDate: '2025-01-01T00:00:00.000Z',
      instructor: 'Sara Johnson',
      fee: 30000,
      categoryId: '66aa3aeb434ffd1cd92dbbab',
      syllabus: [
        'Statistical Analysis',
        'Machine Learning Algorithms',
        'Data Visualization',
        'Big Data Technologies',
      ],
      prerequisites: ['Basic statistics knowledge', 'Python programming'],
      materials: [
        'Data Science from Scratch (book)',
        'Jupyter Notebooks',
        'Dataset access',
      ],
    },
    {
      id: 3,
      courseName: 'Intro to Machine Learning',
      description:
        'Fundamentals of machine learning algorithms and applications',
      duration: '3 Months',
      startDate: '2024-10-01T00:00:00.000Z',
      endDate: '2025-01-01T00:00:00.000Z',
      instructor: 'Tom Wilson',
      fee: 20000,
      categoryId: '66aa3aeb434ffd1cd92dbbab',
      syllabus: [
        'Supervised Learning',
        'Unsupervised Learning',
        'Neural Networks',
        'Model Evaluation',
      ],
      prerequisites: ['Linear Algebra', 'Calculus', 'Python programming'],
      materials: [
        'Introduction to Machine Learning with Python (book)',
        'Google Colab access',
      ],
    },
  ]);
  const [editingCourse, setEditingCourse] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);

  const [filteredCourses, setFilteredCourses] = useState(courses);

  const handleSearch = (value) => {
    const filtered = courses.filter((course) =>
      course.courseName.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredCourses(filtered);
  };

  const handleEdit = (course) => {
    setEditingCourse(course);
    setIsModalVisible(true);
  };

  const handleDelete = (id) => {
    setCourses(courses.filter((course) => course.id !== id));
  };

  const handleView = (course) => {
    setCurrentCourse(course);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setEditingCourse(null);
    setCurrentCourse(null);
  };

  const handleOk = (values) => {
    if (editingCourse) {
      setCourses(
        courses.map((course) =>
          course.id === editingCourse.id
            ? { ...editingCourse, ...values }
            : course,
        ),
      );
    } else {
      setCourses([...courses, { id: courses.length + 1, ...values }]);
    }
    handleCancel();
  };

  const columns = [
    {
      title: 'Module Name',
      dataIndex: 'courseName',
      key: 'Module Name',
      sorter: (a, b) => a.courseName.localeCompare(b.courseName),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Materials',
      dataIndex: 'materials',
      key: 'materials',
    },
    {
      title: 'Status',
      key: 'Status',
      render: (_, record) => <Tag color="green">Active</Tag>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];
  const ViewModal = ({ course, visible, onCancel }) => {
    if (!course) return null;

    const startDate = new Date(course.startDate);
    const endDate = new Date(course.endDate);
    const durationInDays = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24),
    );

    const modalStyle = {
      backgroundColor: '#f0f2f5',
    };

    const tabStyle = {
      padding: '20px',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    };

    const titleStyle = {
      color: '#1890ff',
      marginBottom: '20px',
    };

    const infoItemStyle = {
      fontSize: '16px',
      marginBottom: '10px',
    };

    const iconStyle = {
      color: '#1890ff',
      marginRight: '8px',
    };

    const listItemStyle = {
      fontSize: '16px',
      marginBottom: '8px',
      color: '#333',
    };

    return (
      <Modal
        title={
          <Typography.Title level={3} style={titleStyle}>
            {course.courseName}
          </Typography.Title>
        }
        visible={visible}
        footer={
          <Button onClick={onCancel} type="primary" size="large">
            Close
          </Button>
        }
        onCancel={onCancel}
        width={800}
        bodyStyle={modalStyle}
        className="view-modal"
      >
        <Tabs defaultActiveKey="1" type="card">
          <TabPane
            tab={
              <span>
                <InfoCircleOutlined />
                Overview
              </span>
            }
            key="1"
          >
            <div style={tabStyle}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography.Paragraph
                    style={{ ...infoItemStyle, fontSize: '18px' }}
                  >
                    <strong>Description:</strong> {course.description}
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Space style={infoItemStyle}>
                    <ClockCircleOutlined style={iconStyle} />
                    <span>
                      <strong>Duration:</strong> {course.duration}
                    </span>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space style={infoItemStyle}>
                    <CalendarOutlined style={iconStyle} />
                    <span>
                      <strong>Total Days:</strong> {durationInDays} days
                    </span>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space style={infoItemStyle}>
                    <CalendarOutlined style={iconStyle} />
                    <span>
                      <strong>Start Date:</strong>{' '}
                      {startDate.toLocaleDateString()}
                    </span>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space style={infoItemStyle}>
                    <CalendarOutlined style={iconStyle} />
                    <span>
                      <strong>End Date:</strong> {endDate.toLocaleDateString()}
                    </span>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space style={infoItemStyle}>
                    <UserOutlined style={iconStyle} />
                    <span>
                      <strong>Instructor:</strong> {course.instructor}
                    </span>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space style={infoItemStyle}>
                    <DollarOutlined style={iconStyle} />
                    <span>
                      <strong>Fee:</strong> ${course.fee}
                    </span>
                  </Space>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                <BookOutlined />
                Syllabus
              </span>
            }
            key="2"
          >
            <div style={tabStyle}>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {course.syllabus.map((item, index) => (
                  <li key={index} style={listItemStyle}>
                    <BookOutlined style={iconStyle} /> {item}
                  </li>
                ))}
              </ul>
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                <ToolOutlined />
                Prerequisites
              </span>
            }
            key="3"
          >
            <div style={tabStyle}>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {course.prerequisites.map((item, index) => (
                  <li key={index} style={listItemStyle}>
                    <ToolOutlined style={iconStyle} /> {item}
                  </li>
                ))}
              </ul>
            </div>
          </TabPane>
          <TabPane
            tab={
              <span>
                <TeamOutlined />
                Materials
              </span>
            }
            key="4"
          >
            <div style={tabStyle}>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {course.materials.map((item, index) => (
                  <li key={index} style={listItemStyle}>
                    <TeamOutlined style={iconStyle} /> {item}
                  </li>
                ))}
              </ul>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    );
  };

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Module List
        </Title>
        <Space>
          <Input.Search
            placeholder="Search courses"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
            className="search-input"
          />
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add New Module
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={filteredCourses}
        rowKey="id"
        className="table-container"
      />
      <Modal
        title={editingCourse ? 'Edit Course' : 'Add New Course'}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form
          initialValues={editingCourse}
          onFinish={handleOk}
          layout="vertical"
        >
          <Form.Item
            name="courseName"
            label="Course Name"
            rules={[
              { required: true, message: 'Please input the course name!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="duration" label="Duration">
            <Input />
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              { required: true, message: 'Please select the start date!' },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="End Date"
            rules={[{ required: true, message: 'Please select the end date!' }]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item name="instructor" label="Instructor">
            <Input />
          </Form.Item>
          <Form.Item
            name="fee"
            label="Fee"
            rules={[{ required: true, message: 'Please input the fee!' }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {editingCourse ? 'Save Changes' : 'Add Course'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ViewModal
        course={currentCourse}
        visible={isViewModalVisible}
        onCancel={handleCancel}
      />
    </>
  );
};

export default Module;
