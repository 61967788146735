import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Typography,
  Row,
  Col,
  Tag,
  Select,
  message,
  Menu,
  Dropdown,
} from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  CaretDownFilled,
  CaretUpFilled,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Redux/States/actions';
import action from '../../../Redux/Country/actions';
import config from '../../../Services/siteConfig';
import { useCallback } from 'react';

const { Title, Paragraph } = Typography;

const State = () => {
  const dispatch = useDispatch();
  const { states, successMessage, actionType, status_code } = useSelector(
    (state) => state.StateReducer,
  );
  const statesList = states?.states || [];
  console.log(statesList);
  const { countries } = useSelector((state) => state.CountryReducer);
  const countrylist = countries?.getCountry || [];

  const getcountry = useCallback(() => {
    dispatch(
      action.getCountryList(`${config.getcountry}?page=${1}&limit=${20}`),
    );
  }, [dispatch]);
  useEffect(() => {
    getcountry();
  }, [dispatch, getcountry]);

  const getstates = useCallback(() => {
    dispatch(actions.getStateList(`${config.getstate}?page=${1}&limit=${20}`));
  }, [dispatch]);
  useEffect(() => {
    getstates();
  }, [dispatch, getstates]);

  const [editingCourse, setEditingCourse] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);

  const handleSearch = (value) => {};

  const handleEdit = (course) => {
    setEditingCourse(course);
    setIsModalVisible(true);
  };

  const handleView = (course) => {
    setCurrentCourse(course);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setEditingCourse(null);
    setCurrentCourse(null);
  };

  const handleOk = (values) => {
    if (editingCourse) {
      const payload = {
        id: editingCourse._id,
        state_code: values.state_code,
        state_name: values.state_name,
      };
      console.log(payload);
      dispatch(actions.updateState(config.updatestate, payload));
    } else {
      const payload = {
        state_name: values.state_name,
        state_code: values.state_code,
        country_id: values.country_id,
      };
      console.log(payload);
      dispatch(actions.createState(config.statecreate, payload));
    }
    handleCancel();
  };

  const handleStatusToggle = (record) => {
    const newStatus = !record.is_active;

    Modal.confirm({
      title: `Are you sure you want to set this grade to ${newStatus ? 'Active' : 'Inactive'}?`,
      onOk() {
        const payload = { id: record._id, is_active: newStatus };
        console.log(payload);
        dispatch(
          actions.deleteState(
            `${config.deletestate}?id=${record._id}&is_active=${newStatus}`,
          ),
        );
      },
    });
  };
  useEffect(() => {
    if (actionType === 'DELETE_STATE_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getstates();
      dispatch(actions.resetActionType());
    } else if (actionType === 'DELETE_STATE_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, getstates, dispatch]);

  useEffect(() => {
    if (actionType === 'UPDATE_STATE_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getstates();
      dispatch(actions.resetActionType());
    } else if (actionType === 'UPDATE_STATE_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, dispatch, getstates]);

  const handleSort = (order) => {
    console.log('Sort Order:', order);
    dispatch(
      actions.sortState(
        `${config.getstate}?sort_order=${order}&page=${1}&limit=${20}`,
      ),
    );
  };
  const columns = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ marginRight: 10 }}>State Name</span>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '2px   ' }}
          >
            <span
              onClick={() => handleSort('ASC')}
              style={{ cursor: 'pointer' }}
              aria-label="Sort Ascending"
            >
              <CaretUpFilled style={{ fontSize: 10 }} />
            </span>
            <span
              onClick={() => handleSort('DESC')}
              style={{ cursor: 'pointer' }}
              aria-label="Sort Descending"
            >
              <CaretDownFilled style={{ fontSize: 10 }} />
            </span>
          </div>
        </div>
      ),
      dataIndex: 'state_name',
      key: 'state_name',
    },
    {
      title: 'State Code',
      dataIndex: 'state_code',
      key: 'state_code',
    },

    {
      title: 'Status',
      key: 'is_active',
      dataIndex: 'is_active',
      render: (is_active, record) => (
        <Tag
          color={is_active ? 'green' : 'red'}
          onClick={() => handleStatusToggle(record)}
          style={{ cursor: 'pointer' }}
        >
          {is_active ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];
  const ViewModal = ({ course, visible, onCancel }) => {
    if (!course) return null;

    const modalStyle = {
      backgroundColor: '#f0f2f5',
    };

    const titleStyle = {
      color: '#1890ff',
      marginBottom: '20px',
    };

    const infoItemStyle = {
      fontSize: '16px',
      marginBottom: '10px',
    };

    const sectionStyle = {
      backgroundColor: '#ffffff',
    };

    return (
      <Modal
        title={
          <Typography.Title level={3} style={titleStyle}>
            {course.courseName}
          </Typography.Title>
        }
        visible={visible}
        footer={
          <Button onClick={onCancel} type="primary" size="large">
            Close
          </Button>
        }
        onCancel={onCancel}
        width={500}
        bodyStyle={modalStyle}
        className="view-modal"
      >
        <div style={sectionStyle}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Paragraph style={infoItemStyle}>
                <strong>Country Name:</strong> {course.state_name}
              </Paragraph>
              <Paragraph style={infoItemStyle}>
                <strong>Country Code:</strong> {course.state_code}
              </Paragraph>

              <Paragraph style={infoItemStyle}>
                <strong>Status:</strong>{' '}
                {course.is_active ? 'Active' : 'Inactive'}
              </Paragraph>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
      <Menu.Item key="3">Option 3</Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          State Details
        </Title>
        <Space>
          <Input.Search
            placeholder="Search courses"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
            className="search-input"
          />{' '}
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="primary" className="dropdown-button">
              Filtered By
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add State
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={statesList}
        rowKey="id"
        className="table-container"
      />
      <Modal
        title={editingCourse ? 'Edit State' : 'Add New State'}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form
          initialValues={editingCourse}
          onFinish={handleOk}
          layout="vertical"
        >
          <Form.Item
            name="country_id"
            label="Country Id"
            rules={[{ required: true, message: 'Please select a country!' }]}
          >
            <Select placeholder="Select a country">
              {(countrylist || []).map((country) => (
                <Select.Option key={country._id} value={country._id}>
                  {country.country_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="state_name"
            label="State Name"
            rules={[
              { required: true, message: 'Please input the state name!' },
              {
                min: 2,
                max: 30,
                message: 'State name must be between 2 and 30 characters!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="state_code"
            label="State Code"
            rules={[
              { required: true, message: 'Please input the state code!' },
              { len: 2, message: 'State code must be exactly 2 characters!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {editingCourse ? 'Save Changes' : 'Add Course'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <ViewModal
        course={currentCourse}
        visible={isViewModalVisible}
        onCancel={handleCancel}
      />
    </>
  );
};

export default State;
