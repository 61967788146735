const actions = {
  CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_ERROR: 'CREATE_CATEGORY_ERROR',
  GET_CATEGORY_LIST_REQUEST: 'GET_CATEGORY_LIST_REQUEST',
  GET_CATEGORY_LIST_SUCCESS: 'GET_CATEGORY_LIST_SUCCESS',
  GET_CATEGORY_LIST_ERROR: 'GET_CATEGORY_LIST_ERROR',
  UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',
  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',
  SORT_REQUEST: 'SORT_REQUEST',
  SORT_SUCCESS: 'SORT_SUCCESS',
  SORT_ERROR: 'SORT_ERROR',
  RESET_ACTIONTYPE: 'RESET_ACTIONTYPE',

  createCategory: (url, body) => ({
    type: actions.CREATE_CATEGORY_REQUEST,
    payload: { url, body },
  }),
  getCategoryList: (url) => ({
    type: actions.GET_CATEGORY_LIST_REQUEST,
    payload: { url },
  }),
  updateCategory: (url, body) => ({
    type: actions.UPDATE_CATEGORY_REQUEST,
    payload: { url, body },
  }),
  deleteCategory: (url) => ({
    type: actions.DELETE_CATEGORY_REQUEST,
    payload: { url },
  }),
  sortCategory: (url, body) => ({
    type: actions.SORT_REQUEST,
    payload: { url, body },
  }),
  resetActionType: () => ({
    type: actions.RESET_ACTIONTYPE,
  }),
};

export default actions;
