import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Typography,
  Row,
  Col,
  message,
  Tag,
  Menu,
  Dropdown,
} from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  CaretDownFilled,
  CaretUpFilled,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Redux/Country/actions';
import config from '../../../Services/siteConfig';
import { useCallback } from 'react';

const { Title, Paragraph } = Typography;

const Country = () => {
  const dispatch = useDispatch();
  const { countries, successMessage, actionType, status_code } = useSelector(
    (state) => state.CountryReducer,
  );
  const countrylist = countries?.getCountry || [];

  const [editingCoutry, seteditingCoutry] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);

  const handleSearch = (value) => {};
  const handleEdit = (country) => {
    seteditingCoutry(country);
    setIsModalVisible(true);
  };

  const handleView = (course) => {
    setCurrentCourse(course);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    seteditingCoutry(null);
    setCurrentCourse(null);
  };

  const getcountry = useCallback(() => {
    dispatch(
      actions.getCountryList(`${config.getcountry}?page=${1}&limit=${20}`),
    );
  }, [dispatch]);
  useEffect(() => {
    getcountry();
  }, [dispatch, getcountry]);

  const handleOk = (values) => {
    if (editingCoutry) {
      const payload = {
        id: editingCoutry._id,
        country_name: values.country_name,
        country_code: values.country_code,
      };
      dispatch(actions.updateCountry(config.updatecountry, payload));
    } else {
      dispatch(actions.createCountry(config.createcountry, values));
    }
    handleCancel();
  };

  useEffect(() => {
    if (actionType === 'CREATE_COUNTRY_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getcountry();
      dispatch(actions.resetActionType());
    } else if (actionType === 'CREATE_COUNTRY_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, dispatch, getcountry]);

  const handleStatusToggle = (record) => {
    const newStatus = !record.is_active;

    Modal.confirm({
      title: `Are you sure you want to set this grade to ${newStatus ? 'Active' : 'Inactive'}?`,
      onOk() {
        const payload = { id: record._id, is_active: newStatus };
        console.log(payload);
        dispatch(
          actions.deleteCountry(
            `${config.deletecountry}?id=${record._id}&is_active=${newStatus}`,
          ),
        );
      },
    });
  };
  useEffect(() => {
    if (actionType === 'DELETE_COUNTRY_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getcountry();
      dispatch(actions.resetActionType());
    } else if (actionType === 'DELETE_COUNTRY_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, dispatch, getcountry]);

  useEffect(() => {
    if (actionType === 'UPDATE_COUNTRY_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getcountry();
      dispatch(actions.resetActionType());
    } else if (actionType === 'UPDATE_COUNTRY_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, getcountry, dispatch]);

  const handleSort = (order) => {
    console.log('Sort Order:', order);
    dispatch(
      actions.sortCountryList(
        `${config.getcountry}?sort_order=${order}&  page=${1}&limit=${20}`,
      ),
    );
  };
  const columns = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ marginRight: 10 }}>Country Name</span>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '2px   ' }}
          >
            <span
              onClick={() => handleSort('ASC')}
              style={{ cursor: 'pointer' }}
              aria-label="Sort Ascending"
            >
              <CaretUpFilled style={{ fontSize: 10 }} />
            </span>
            <span
              onClick={() => handleSort('DESC')}
              style={{ cursor: 'pointer' }}
              aria-label="Sort Descending"
            >
              <CaretDownFilled style={{ fontSize: 10 }} />
            </span>
          </div>
        </div>
      ),
      dataIndex: 'country_name',
      key: 'countryName',
    },
    {
      title: 'Country Code',
      dataIndex: 'country_code',
      key: 'CountryCode',
    },
    {
      title: 'Status',
      key: 'is_active',
      dataIndex: 'is_active',
      render: (is_active, record) => (
        <Tag
          color={is_active ? 'green' : 'red'}
          onClick={() => handleStatusToggle(record)}
          style={{ cursor: 'pointer' }}
        >
          {is_active ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];
  const ViewModal = ({ course, visible, onCancel }) => {
    if (!course) return null;

    const modalStyle = {
      backgroundColor: '#f0f2f5',
    };
    const sectionStyle = {
      backgroundColor: '#ffffff',
    };

    const titleStyle = {
      color: '#1890ff',
      marginBottom: '20px',
    };

    const infoItemStyle = {
      fontSize: '16px',
      marginBottom: '10px',
    };

    return (
      <Modal
        title={
          <Typography.Title level={3} style={titleStyle}>
            {course.courseName}
          </Typography.Title>
        }
        visible={visible}
        footer={
          <Button onClick={onCancel} type="primary" size="large">
            Close
          </Button>
        }
        onCancel={onCancel}
        width={500}
        bodyStyle={modalStyle}
        className="view-modal"
      >
        {' '}
        <div style={sectionStyle}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Paragraph style={infoItemStyle}>
                <strong>Country Name:</strong> {course.country_name}
              </Paragraph>
              <Paragraph style={infoItemStyle}>
                <strong>Country Code:</strong> {course.country_code}
              </Paragraph>

              <Paragraph style={infoItemStyle}>
                <strong>Status:</strong>{' '}
                {course.is_active ? 'Active' : 'Inactive'}
              </Paragraph>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
      <Menu.Item key="3">Option 3</Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Country Details
        </Title>
        <Space>
          <Input.Search
            placeholder="Search courses"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
            className="search-input"
          />{' '}
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="primary" className="dropdown-button">
              Filtered By
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add Grade
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={countrylist}
        rowKey="id"
        className="table-container"
      />
      <Modal
        title={editingCoutry ? 'Edit Country' : 'Add New Country'}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form
          onFinish={handleOk}
          layout="vertical"
          initialValues={
            editingCoutry
              ? {
                  country_name: editingCoutry.country_name,
                  country_code: editingCoutry.country_code,
                }
              : {}
          }
        >
          <Form.Item
            name="country_name"
            label="Country Name"
            rules={[
              { required: true, message: 'Please input the country name!' },
              {
                min: 2,
                max: 30,
                message: 'Country name must be between 2 and 30 characters!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country_code"
            label="Country Code"
            rules={[
              { required: true, message: 'Please input the country code!' },
              {
                min: 2,
                max: 4,
                message: 'Country code must be between 2 and 4 characters!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {editingCoutry ? 'Save Changes' : 'Add Country'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <ViewModal
        course={currentCourse}
        visible={isViewModalVisible}
        onCancel={handleCancel}
      />
    </>
  );
};

export default Country;
