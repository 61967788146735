import React from 'react';
import {
  Card,
  Col,
  Row,
  Table,
  Button,
  Typography,
  Space,
  Statistic,
  Avatar,
  List,
  Tag,
  Timeline,
} from 'antd';
import {
  BarChartOutlined,
  PieChartOutlined,
  LineChartOutlined,
  MessageOutlined,
  TrophyOutlined,
  FileDoneOutlined,
  StarOutlined,
  UserOutlined,
  BookOutlined,
  TeamOutlined,
  RiseOutlined,
} from '@ant-design/icons';
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from 'recharts';
import './dashboard.css'; // Import the CSS file

const { Title, Text } = Typography;

const studentsEnrollmentData = [
  { date: 'Last 30 Days', value: 5490 },
  { date: 'This Month', value: 14804 },
  { date: 'This Week', value: 1804 },
];

const activeStudentsData = [
  { period: 'Monthly', value: 9280 },
  { period: 'Weekly', value: 2690 },
  { period: 'Daily (Avg)', value: 940 },
];

const topCoursesData = [
  {
    key: '1',
    course: 'UI/UX Design with Adobe XD',
    price: '$85.00',
    totalSales: '$2,125.00',
    sold: '25 Sold',
  },
  {
    key: '2',
    course: 'Android App Development',
    price: '$95.00',
    totalSales: '$1,710.00',
    sold: '10 Sold',
  },
  {
    key: '3',
    course: 'Wordpress Development',
    price: '$70.00',
    totalSales: '$1,050.00',
    sold: '15 Sold',
  },
  {
    key: '4',
    course: 'Machine Learning',
    price: '$110.00',
    totalSales: '$990.00',
    sold: '10 Sold',
  },
];

const supportRequestsData = [
  {
    key: '1',
    name: 'Vincent Lopez',
    message: 'Thanks for contacting us with your issues...',
    time: '6 min ago',
  },
  {
    key: '2',
    name: 'Daniel Moore',
    message: 'Thanks for contacting us with your issues...',
    time: '2 Hours ago',
  },
  {
    key: '3',
    name: 'Larry Henry',
    message: 'Thanks for contacting us with your issues...',
    time: '3 Hours ago',
  },
];

const courseCompletionData = [
  { month: 'Jan', completion: 65 },
  { month: 'Feb', completion: 59 },
  { month: 'Mar', completion: 80 },
  { month: 'Apr', completion: 71 },
  { month: 'May', completion: 56 },
  { month: 'Jun', completion: 55 },
];

const courseRatingsData = [
  { name: '5 Stars', value: 452 },
  { name: '4 Stars', value: 312 },
  { name: '3 Stars', value: 125 },
  { name: '2 Stars', value: 57 },
  { name: '1 Star', value: 23 },
];

const colors = {
  primary: '#1890ff',
  success: '#52c41a',
  warning: '#faad14',
  error: '#f5222d',
  purple: '#722ed1',
  cyan: '#13c2c2',
  orange: '#fa8c16',
  green: '#a0d911',
  pink: '#eb2f96',
  blue: '#2f54eb',
};

const pieColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const DashboardCard = ({ title, icon, extra, children, color, className }) => (
  <Card
    title={
      <Space className="dashboard-card-title">
        <Avatar icon={icon} style={{ backgroundColor: color, color: '#fff' }} />
        <span className="dashboard-card-title-text" style={{ color }}>
          {title}
        </span>
      </Space>
    }
    extra={extra}
    bordered={false}
    className={`dashboard-card ${className}`}
  >
    {children}
  </Card>
);

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <Title level={2} className="dashboard-title">
        Welcome to Dashboard
      </Title>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <DashboardCard
            title="Total Students"
            icon={<UserOutlined />}
            color={colors.primary}
            className="total-students-card"
          >
            <Statistic
              value={15632}
              valueStyle={{ color: colors.primary }}
              prefix={<UserOutlined />}
            />
          </DashboardCard>
        </Col>

        <Col xs={24} sm={12} lg={6}>
          <DashboardCard
            title="Active Courses"
            icon={<BookOutlined />}
            color={colors.success}
            className="active-courses-card"
          >
            <Statistic
              value={243}
              valueStyle={{ color: colors.success }}
              prefix={<BookOutlined />}
            />
          </DashboardCard>
        </Col>

        <Col xs={24} sm={12} lg={6}>
          <DashboardCard
            title="Total Instructors"
            icon={<TeamOutlined />}
            color={colors.warning}
            className="total-instructors-card"
          >
            <Statistic
              value={52}
              valueStyle={{ color: colors.warning }}
              prefix={<TeamOutlined />}
            />
          </DashboardCard>
        </Col>

        <Col xs={24} sm={12} lg={6}>
          <DashboardCard
            title="Revenue"
            icon={<RiseOutlined />}
            color={colors.purple}
            className="revenue-card"
          >
            <Statistic
              value={285690}
              prefix="$"
              valueStyle={{ color: colors.purple }}
            />
          </DashboardCard>
        </Col>

        <Col xs={24} lg={12}>
          <DashboardCard
            title="Students Enrollment"
            icon={<BarChartOutlined />}
            color={colors.primary}
            className="students-enrollment-card"
            extra={<Button type="link">More Info</Button>}
          >
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={studentsEnrollmentData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" name="Enrollments" fill={colors.primary} />
              </BarChart>
            </ResponsiveContainer>
          </DashboardCard>
        </Col>

        <Col xs={24} lg={12}>
          <DashboardCard
            title="Active Students Distribution"
            icon={<PieChartOutlined />}
            color={colors.cyan}
            className="active-students-distribution-card"
            extra={<Button type="link">View Details</Button>}
          >
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={activeStudentsData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) =>
                    ` ${name} ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {activeStudentsData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieColors[index % pieColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </DashboardCard>
        </Col>

        <Col xs={24} lg={12}>
          <DashboardCard
            title="Course Completion Rate"
            icon={<LineChartOutlined />}
            color={colors.success}
            className="course-completion-rate-card"
            extra={<Button type="link">Details</Button>}
          >
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={courseCompletionData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="completion"
                  name="Completion Rate"
                  stroke={colors.success}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </DashboardCard>
        </Col>

        <Col xs={24} lg={12}>
          <DashboardCard
            title="Top Courses"
            icon={<TrophyOutlined />}
            color={colors.orange}
            className="top-courses-card"
            extra={<Button type="link">View All</Button>}
          >
            <Table
              className="top-courses-card2"
              dataSource={topCoursesData}
              columns={[
                {
                  title: 'Course',
                  dataIndex: 'course',
                  key: 'course',
                  render: (text) => (
                    <Text strong className="text">
                      {text}
                    </Text>
                  ),
                },
                {
                  title: 'Price',
                  dataIndex: 'price',
                  key: 'price',
                  render: (text) => (
                    <Tag color={colors.cyan} className="text">
                      {text}
                    </Tag>
                  ),
                },
                {
                  title: 'Total Sales',
                  dataIndex: 'totalSales',
                  key: 'totalSales',
                  render: (text) => (
                    <Text type="success" className="text">
                      {text}
                    </Text>
                  ),
                },
                {
                  title: 'Sold',
                  dataIndex: 'sold',
                  key: 'sold',

                  render: (text) => (
                    <Text status="processing" className="text">
                      {text}
                    </Text>
                  ),
                },
              ]}
              pagination={false}
              size="small"
            />
          </DashboardCard>
        </Col>

        <Col xs={24} lg={12}>
          <DashboardCard
            title="Support Requests"
            icon={<MessageOutlined />}
            color={colors.green}
            className="support-requests-card"
            extra={<Button type="link">View All</Button>}
          >
            <List
              itemLayout="horizontal"
              dataSource={supportRequestsData}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        icon={<UserOutlined />}
                        style={{ backgroundColor: colors.green }}
                      />
                    }
                    title={item.name}
                    description={item.message}
                  />
                  <Text type="secondary">{item.time}</Text>
                </List.Item>
              )}
            />
          </DashboardCard>
        </Col>

        <Col xs={24} lg={12}>
          <DashboardCard
            title="Course Ratings"
            icon={<StarOutlined />}
            color={colors.pink}
            className="course-ratings-card"
            extra={<Button type="link">View Details</Button>}
          >
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={courseRatingsData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) =>
                    ` ${name}: ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {courseRatingsData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieColors[index % pieColors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </DashboardCard>
        </Col>

        <Col xs={24}>
          <DashboardCard
            title="Recent Activities"
            icon={<FileDoneOutlined />}
            color={colors.blue}
            className="recent-activities-card"
            extra={<Button type="link">View All</Button>}
          >
            <Timeline className="recent-activities-card2">
              <Timeline.Item color={colors.green}>
                New course Advanced React added
              </Timeline.Item>
              <Timeline.Item color={colors.blue}>
                10 new students enrolled in Machine Learning Basics
              </Timeline.Item>
              <Timeline.Item color={colors.error}>
                Deadline for Java Programming extended to August 30
              </Timeline.Item>
              <Timeline.Item color={colors.orange}>
                Instructor John Doe updated Web Development course materials
              </Timeline.Item>
            </Timeline>
          </DashboardCard>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
