import React, { useState, useCallback, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Typography,
  Row,
  Col,
  Tag,
  Menu,
  Select,
  Dropdown,
  message,
} from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  CaretUpFilled,
  CaretDownFilled,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Redux/Courses/actions';
import action from '../../../Redux/catagory/actions';
import config from '../../../Services/siteConfig';

const { Title, Paragraph } = Typography;

const Course = () => {
  const dispatch = useDispatch();
  const { courses, successMessage, actionType, status_code } = useSelector(
    (state) => state.CourseReducer,
  );
  const { categories } = useSelector((state) => state.CategoryReducer);
  const courseslist = courses?.courses || [];

  const [editingCourse, setEditingCourse] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);

  const handleSearch = (value) => {};

  const handleEdit = (course) => {
    setEditingCourse(course);
    setIsModalVisible(true);
  };

  const handleView = (course) => {
    setCurrentCourse(course);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setEditingCourse(null);
    setCurrentCourse(null);
  };

  const getcourse = useCallback(() => {
    dispatch(
      actions.getCourseList(`${config.courseget}?page=${1}&limit=${20}`),
    );
  }, [dispatch]);
  useEffect(() => {
    getcourse();
  }, [dispatch, getcourse]);

  const getCategoryList = useCallback(() => {
    dispatch(action.getCategoryList(`${config.getCategory}?page=1&limit=20`));
  }, [dispatch]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);
  const handleOk = (values) => {
    if (editingCourse) {
      const updatedValues = {
        ...values,
        courseId: editingCourse._id,
      };

      dispatch(actions.updateCourse(config.courseupdate, updatedValues));
    } else {
      console.log('create', values);
      dispatch(actions.createCourse(config.coursecreate, values));
    }

    handleCancel();
  };

  useEffect(() => {
    if (actionType === 'CREATE_COURSE_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getcourse();
      dispatch(actions.resetActionType());
    } else if (actionType === 'CREATE_COURSE_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, dispatch, getcourse]);

  const handleStatusToggle = (record) => {
    const newStatus = !record.is_active;

    Modal.confirm({
      title: `Are you sure you want to set this grade to ${newStatus ? 'Active' : 'Inactive'}?`,
      onOk() {
        const payload = { courseId: record._id, is_active: newStatus };
        console.log(payload);
        dispatch(
          actions.deleteCourse(
            `${config.coursedelete}?courseId=${record._id}&is_active=${newStatus}`,
          ),
        );
      },
    });
  };

  useEffect(() => {
    if (actionType === 'DELETE_COURSE_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getcourse();
      dispatch(actions.resetActionType());
    } else if (actionType === 'DELETE_COURSE_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, dispatch, getcourse]);

  useEffect(() => {
    if (actionType === 'UPDATE_COURSE_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getcourse();
      dispatch(actions.resetActionType());
    } else if (actionType === 'UPDATE_COURSE_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, getcourse, dispatch]);

  const handleSort = (order) => {
    console.log('Sort Order:', order);
    dispatch(
      actions.sortCourseList(
        `${config.courseget}?sort_order=${order}&page=${1}&limit=${20}`,
      ),
    );
  };
  const columns = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ marginRight: 10 }}> Course Title</span>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '2px   ' }}
          >
            <span
              onClick={() => handleSort('ASC')}
              style={{ cursor: 'pointer' }}
              aria-label="Sort Ascending"
            >
              <CaretUpFilled style={{ fontSize: 10 }} />
            </span>
            <span
              onClick={() => handleSort('DESC')}
              style={{ cursor: 'pointer' }}
              aria-label="Sort Descending"
            >
              <CaretDownFilled style={{ fontSize: 10 }} />
            </span>
          </div>
        </div>
      ),
      dataIndex: 'course_title',
      key: 'course_title',
    },
    {
      title: 'Course Code',
      dataIndex: 'course_code',
      key: 'course_code',
    },
    {
      title: 'Course Description',
      dataIndex: 'course_description',
      key: 'course_description',
    },
    {
      title: 'Course Category',
      dataIndex: 'course_category',
      key: 'course_category',
    },
    {
      title: 'Status',
      key: 'is_active',
      dataIndex: 'is_active',
      render: (is_active, record) => (
        <Tag
          color={is_active ? 'green' : 'red'}
          onClick={() => handleStatusToggle(record)}
          style={{ cursor: 'pointer' }}
        >
          {is_active ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];
  const ViewModal = ({ course, visible, onCancel }) => {
    if (!course) return null;

    const modalStyle = {
      backgroundColor: '#f0f2f5',
    };

    const sectionStyle = {
      backgroundColor: '#ffffff',
    };

    const titleStyle = {
      color: '#1890ff',
      marginBottom: '20px',
    };

    const infoItemStyle = {
      fontSize: '16px',
      marginBottom: '10px',
    };

    return (
      <Modal
        title={
          <Typography.Title level={3} style={titleStyle}>
            {course.courseName}
          </Typography.Title>
        }
        visible={visible}
        footer={
          <Button onClick={onCancel} type="primary" size="large">
            Close
          </Button>
        }
        onCancel={onCancel}
        width={800}
        bodyStyle={modalStyle}
        className="view-modal"
      >
        <div style={sectionStyle}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Paragraph style={infoItemStyle}>
                <strong>Country Name:</strong> {course.course_title}
              </Paragraph>
              <Paragraph style={infoItemStyle}>
                <strong>Country Code:</strong> {course.course_code}
              </Paragraph>
              <Paragraph style={infoItemStyle}>
                <strong>Country Code:</strong> {course.course_description}
              </Paragraph>{' '}
              <Paragraph style={infoItemStyle}>
                <strong>Country Code:</strong> {course.course_category}
              </Paragraph>
              <Paragraph style={infoItemStyle}>
                <strong>Status:</strong>{' '}
                {course.is_active ? 'Active' : 'Inactive'}
              </Paragraph>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
      <Menu.Item key="3">Option 3</Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Course List
        </Title>
        <Space>
          <Input.Search
            placeholder="Search courses"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
            className="search-input"
          />
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="primary" className="dropdown-button">
              Filtered By
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add New Course
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={courseslist}
        rowKey="id"
        className="table-container"
      />
      <Modal
        title={editingCourse ? 'Edit Course' : 'Add New Course'}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form
          initialValues={editingCourse}
          onFinish={handleOk}
          layout="vertical"
        >
          <Form.Item
            name="categoryId"
            label="Category Name"
            rules={[{ required: true, message: 'Category Id is required!' }]}
          >
            <Select placeholder="Select a category">
              {categories?.map((category) => (
                <Select.Option key={category._id} value={category._id}>
                  {category.category_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>{' '}
          <Form.Item
            name="course_category"
            label="Course Category"
            rules={[
              { required: true, message: 'Course category is required!' },
            ]}
          >
            <Select placeholder="Select a country">
              <Select.Option value="IT">IT</Select.Option>
              <Select.Option value="Non-IT">Non-IT</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="course_title"
            label="Course Title"
            rules={[
              { required: true, message: 'Course title is required!' },
              {
                min: 3,
                max: 10,
                message: 'Course title must be between 3 and 10 characters!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="course_code"
            label="Course Code"
            rules={[
              { required: true, message: 'Course code is required!' },
              {
                min: 3,
                max: 20,
                message: 'Course code must be between 3 and 20 characters!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="course_description"
            label="Course Description"
            rules={[
              { required: true, message: 'Course description is required!' },
              {
                min: 3,
                max: 500,
                message:
                  'Course description must be between 3 and 500 characters!',
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {editingCourse ? 'Save Changes' : 'Add Course'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ViewModal
        course={currentCourse}
        visible={isViewModalVisible}
        onCancel={handleCancel}
      />
    </>
  );
};

export default Course;
