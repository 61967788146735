const config = {
  createCategory: 'category/create',
  getCategory: 'category/get',
  updateCategory: 'category/update',
  deleteCategory: 'category/delete',
  Relationship: 'parent/parentTypes',
  yearofpass: 'yearofpassing/createyearofpassing',
  getYearofpass: 'yearofpassing/getyearsofpassing',
  updateYearofpass: 'yearofpassing/updateyearofpassing',
  deleteYearofpass: 'yearofpassing/deleteyearofpassing',
  createQualification: 'qualification/createqualification',
  getQualification: 'qualification/getqualification',
  updateQualification: 'qualification/updatequalification',
  deleteQualification: 'qualification/deletequalification',
  jobtitle: '/joblevel/joblevel',
  creategrade: 'grade/create',
  getgradelist: 'grade/get',
  gradeupdate: 'grade/update',
  gradedelete: 'grade/delete',
  createcountry: 'country/create',
  getcountry: 'country/get',
  updatecountry: 'country/update',
  deletecountry: 'country/delete',
  coursecreate: 'course/createcourse',
  coursedelete: 'course/deletecourse',
  courseupdate: 'course/updatecourse',
  courseget: 'course/getcourse',
  statecreate: 'state/create',
  deletestate: 'state/delete',
  getstate: 'state/getState',
  updatestate: 'state/update',
};
export default config;
