import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createCountryApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const updateCountryApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

const deleteCountryApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};

const getCountryListApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const sortCountryListApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

export function* createCountrySaga() {
  yield takeEvery(actions.CREATE_COUNTRY_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createCountryApi, payload);
      if (res) {
        yield put({
          type: actions.CREATE_COUNTRY_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.CREATE_COUNTRY_ERROR,
          errorMessage: 'Failed to create country. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_COUNTRY_ERROR,
        errorMessage:
          'Something went wrong during country creation. Please try again.',
      });
    }
  });
}

export function* updateCountrySaga() {
  yield takeEvery(actions.UPDATE_COUNTRY_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateCountryApi, payload);
      if (res) {
        yield put({
          type: actions.UPDATE_COUNTRY_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.UPDATE_COUNTRY_ERROR,
          errorMessage: 'Failed to update country. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_COUNTRY_ERROR,
        errorMessage:
          'Something went wrong during country update. Please try again.',
      });
    }
  });
}

export function* deleteCountrySaga() {
  yield takeEvery(actions.DELETE_COUNTRY_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteCountryApi, payload);
      if (res) {
        yield put({
          type: actions.DELETE_COUNTRY_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.DELETE_COUNTRY_ERROR,
          errorMessage: 'Failed to delete country. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_COUNTRY_ERROR,
        errorMessage:
          'Something went wrong during country deletion. Please try again.',
        status_code: error.response ? error.response.status : 500,
      });
    }
  });
}

export function* getCountryListSaga() {
  yield takeEvery(actions.GET_COUNTRY_LIST_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getCountryListApi, payload);
      if (res) {
        yield put({
          type: actions.GET_COUNTRY_LIST_SUCCESS,
          data: res.data,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.GET_COUNTRY_LIST_ERROR,
          errorMessage: 'Failed to fetch countries. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_COUNTRY_LIST_ERROR,
        errorMessage:
          'Something went wrong while fetching countries. Please try again.',
      });
    }
  });
}

export function* sortCountryListSaga() {
  yield takeEvery(actions.SORT_COUNTRY_LIST_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(sortCountryListApi, payload);
      if (res) {
        yield put({
          type: actions.SORT_COUNTRY_LIST_SUCCESS,
          data: res.data,
          status_code: res.status_code,
        });
      } else {
        yield put({
          type: actions.SORT_COUNTRY_LIST_ERROR,
          errorMessage: 'Failed to sort countries. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.SORT_COUNTRY_LIST_ERROR,
        errorMessage:
          'Something went wrong during country sorting. Please try again.',
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createCountrySaga),
    fork(updateCountrySaga),
    fork(deleteCountrySaga),
    fork(getCountryListSaga),
    fork(sortCountryListSaga),
  ]);
}
