import { combineReducers } from 'redux';
import CategoryReducer from '../Redux/catagory/reducer';
import RelationshipReducer from '../Redux/relationship/reducer';
import YearofpassReducer from '../Redux/Yearofpass/reducer';
import QualificationReducer from '../Redux/Education/reducer';
import JobtitlesReducer from '../Redux/jobtitles/reducer';
import GradeReducer from '../Redux/Grades/reducer';
import StateReducer from '../Redux/States/reducer';
import CountryReducer from '../Redux/Country/reducer';
import CourseReducer from '../Redux/Courses/reducer';

export default combineReducers({
  CategoryReducer,
  RelationshipReducer,
  YearofpassReducer,
  QualificationReducer,
  JobtitlesReducer,

  CategoryReducer,
  GradeReducer,
  StateReducer,
  CountryReducer,
  CourseReducer,
});
