import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions'; // Adjust the path if necessary
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createQualificationApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const getQualificationApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};
const UpdateQualificationApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};
const deleteQualificationApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};
export function* createQualificationSaga() {
  yield takeEvery(
    actions.CREATE_QUALIFICATION_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(createQualificationApi, payload);
        if (res?.status_code === 1) {
          yield put({
            type: actions.CREATE_QUALIFICATION__SUCCESS,
            successMessage: res?.message,
          });
        } else {
          yield put({
            type: actions.CREATE_QUALIFICATION__ERROR,
            error: 'Failed to create category. Please try again.',
          });
        }
      } catch (error) {
        yield put({
          type: actions.CREATE_QUALIFICATION__ERROR,
          error:
            'Something went wrong during category creation. Please try again.',
        });
      }
    },
  );
}

export function* getQualificationSaga() {
  yield takeEvery(actions.GET_QUALIFICATION_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getQualificationApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.GET_QUALIFICATION_SUCCESS,
          data: res?.data,
        });
      } else {
        yield put({
          type: actions.GET_QUALIFICATION_ERROR,
          error: 'Failed to fetch categories. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_QUALIFICATION_ERROR,
        error:
          'Something went wrong while fetching categories. Please try again.',
      });
    }
  });
}
export function* updateQualificationSaga() {
  yield takeEvery(
    actions.UPDATE_QUALIFICATION_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(UpdateQualificationApi, payload);
        if (res.status_code === 1) {
          yield put({
            type: actions.UPDATE_QUALIFICATION_SUCCESS,
            successMessage: res?.message,
          });
        } else {
          yield put({
            type: actions.UPDATE_QUALIFICATION_ERROR,
            error: 'Failed to fetch categories. Please try again.',
          });
        }
      } catch (error) {
        yield put({
          type: actions.UPDATE_QUALIFICATION_ERROR,
          error:
            'Something went wrong while fetching categories. Please try again.',
        });
      }
    },
  );
}
export function* deleteQualificationSaga() {
  yield takeEvery(
    actions.DELETE_QUALIFICATION_REQUEST,
    function* ({ payload }) {
      try {
        const res = yield call(deleteQualificationApi, payload);
        if (res.status_code === 1) {
          yield put({
            type: actions.DELETE_QUALIFICATION_SUCCESS,
            successMessage: res?.message,
          });
        } else {
          yield put({
            type: actions.DELETE_QUALIFICATION_ERROR,
            error: 'Failed to fetch categories. Please try again.',
          });
        }
      } catch (error) {
        yield put({
          type: actions.DELETE_QUALIFICATION_ERROR,
          error:
            'Something went wrong while fetching categories. Please try again.',
        });
      }
    },
  );
}

export default function* rootSaga() {
  yield all([
    fork(createQualificationSaga),
    fork(getQualificationSaga),
    fork(updateQualificationSaga),
    fork(deleteQualificationSaga),
  ]);
}
