import actions from './actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  successMessage: '',
  errorMessage: '',
  actionType: '',
  status_code: 0,
  yearofpassdata: [],
});

export default function YearofpassReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATEYEAR_OF_PASS_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'CREATEYEAR_OF_PASS_REQUEST',
      });
    case actions.CREATEYEAR_OF_PASS_REQUEST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATEYEAR_OF_PASS_REQUEST_SUCCESS',
        successMessage: action.successMessage,
        status_code: action.status_code,
      });
    case actions.CREATEYEAR_OF_PASS_REQUEST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATEYEAR_OF_PASS_REQUEST_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.GET_YEAR_OF_PASS_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'GET_YEAR_OF_PASS_REQUEST',
      });
    case actions.GET_YEAR_OF_PASS_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_YEAR_OF_PASS_SUCCESS',
        yearofpassdata: action.data,
      });
    case actions.GET_YEAR_OF_PASS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_YEAR_OF_PASS_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.UPDATE_YEAR_OF_PASS:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'UPDATE_YEAR_OF_PASS',
      });
    case actions.UPDATE_YEAR_OF_PASS_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_YEAR_OF_PASS_SUCCESS',
        successMessage: action.successMessage,
      });
    case actions.UPDATE_YEAR_OF_PASS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_YEAR_OF_PASS_ERROR',
        errorMessage: action.error,
      });
    case actions.DELETE_YEAR_OF_PASS_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'DELETE_YEAR_OF_PASS_REQUEST',
      });
    case actions.DELETE_YEAR_OF_PASS_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_YEAR_OF_PASS_SUCCESS',
        successMessage: action.successMessage,
      });
    case actions.DELETE_YEAR_OF_PASS_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_YEAR_OF_PASS_ERROR',
        errorMessage: action.error,
      });
    case actions.SORT_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'SORT_REQUEST',
      });
    case actions.SORT_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_SUCCESS',
        data: action.data,
      });
    case actions.SORT_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });

    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: '',
        successMessage: '',
        errorMessage: '',
        status_code: 0,
      });
    default:
      return state;
  }
}
