import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createStateApi = async (payload) => {
  const { url, body } = payload;
  return await AxiosInterceptor.post(url, body);
};

const updateStateApi = async (payload) => {
  const { url, body } = payload;
  return await AxiosInterceptor.put(url, body);
};

const deleteStateApi = async (payload) => {
  const { url } = payload;
  return await AxiosInterceptor.delete(url);
};

const getStateListApi = async (payload) => {
  const { url } = payload;
  return await AxiosInterceptor.get(url);
};

const sortstate = async (payload) => {
  const { url } = payload;
  return await AxiosInterceptor.get(url);
};

function* createStateSaga() {
  yield takeEvery(actions.CREATE_STATE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createStateApi, payload);
      if (res) {
        yield put({
          type: actions.CREATE_STATE_SUCCESS,
          message: res.data.message,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_STATE_ERROR,
        errorMessage: 'Failed to create state. Please try again.',
      });
    }
  });
}

function* updateStateSaga() {
  yield takeEvery(actions.UPDATE_STATE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateStateApi, payload);
      if (res) {
        yield put({
          type: actions.UPDATE_STATE_SUCCESS,
          successMessage: res.message,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_STATE_ERROR,
        errorMessage: 'Failed to update state. Please try again.',
      });
    }
  });
}

function* deleteStateSaga() {
  yield takeEvery(actions.DELETE_STATE_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteStateApi, payload);
      if (res) {
        yield put({
          type: actions.DELETE_STATE_SUCCESS,
          message: res.message,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_STATE_ERROR,
        errorMessage: 'Failed to delete state. Please try again.',
        status_code: error.response ? error.response.status : 500,
      });
    }
  });
}

function* getStateListSaga() {
  yield takeEvery(actions.GET_STATE_LIST_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getStateListApi, payload);
      if (res) {
        yield put({
          type: actions.GET_STATE_LIST_SUCCESS,
          data: res.data,
          status_code: res.status_code,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_STATE_LIST_ERROR,
        errorMessage: 'Failed to fetch states. Please try again.',
      });
    }
  });
}

function* sortStateSaga() {
  yield takeEvery(actions.SORT_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(sortstate, payload);
      if (res) {
        yield put({
          type: actions.SORT_SUCCESS,
          data: res.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SORT_ERROR,
        errorMessage: 'Failed to sort states. Please try again.',
      });
    }
  });
}

// Root Saga
export default function* rootSaga() {
  yield all([
    fork(createStateSaga),
    fork(updateStateSaga),
    fork(deleteStateSaga),
    fork(getStateListSaga),
    fork(sortStateSaga),
  ]);
}
