import actions from './actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  successMessage: '',
  errorMessage: '',
  actionType: '',
  status_code: 0,
  states: [],
});

export default function StateReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_STATE_REQUEST:
      return state.merge({
        isFetching: true,
        actionType: 'CREATE_STATE_REQUEST',
      });
    case actions.CREATE_STATE_SUCCESS:
      return state.merge({
        isFetching: false,
        actionType: 'CREATE_STATE_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.CREATE_STATE_ERROR:
      return state.merge({
        isFetching: false,
        actionType: 'CREATE_STATE_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });

    case actions.GET_STATE_LIST_REQUEST:
      return state.merge({
        isFetching: true,
        actionType: 'GET_STATE_LIST_REQUEST',
      });
    case actions.GET_STATE_LIST_SUCCESS:
      return state.merge({
        isFetching: false,
        actionType: 'GET_STATE_LIST_SUCCESS',
        states: action.data,
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.GET_STATE_LIST_ERROR:
      return state.merge({
        isFetching: false,
        actionType: 'GET_STATE_LIST_ERROR',
        errorMessage: action.message,
        status_code: action.status_code,
      });

    case actions.UPDATE_STATE_REQUEST:
      return state.merge({
        isFetching: true,
        actionType: 'UPDATE_STATE_REQUEST',
      });
    case actions.UPDATE_STATE_SUCCESS:
      return state.merge({
        isFetching: false,
        actionType: 'UPDATE_STATE_SUCCESS',
        successMessage: action.successMessage,
        status_code: action.status_code,
      });
    case actions.UPDATE_STATE_ERROR:
      return state.merge({
        isFetching: false,
        actionType: 'UPDATE_STATE_ERROR',
        errorMessage: action.message,
        status_code: action.status_code,
      });

    case actions.DELETE_STATE_REQUEST:
      return state.merge({
        isFetching: true,
        actionType: 'DELETE_STATE_REQUEST',
      });
    case actions.DELETE_STATE_SUCCESS:
      return state.merge({
        isFetching: false,
        actionType: 'DELETE_STATE_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.DELETE_STATE_ERROR:
      return state.merge({
        isFetching: false,
        actionType: 'DELETE_STATE_ERROR',
        errorMessage: action.message,
        status_code: action.status_code,
      });

    case actions.SORT_REQUEST:
      return state.merge({
        isFetching: true,
        actionType: 'SORT_REQUEST',
      });

    case actions.SORT_SUCCESS:
      return state.merge({
        isFetching: false,
        actionType: 'SORT_SUCCESS',
        states: action.data,
        successMessage: action.message,
        status_code: action.status_code,
      });

    case actions.SORT_ERROR:
      return state.merge({
        isFetching: false,
        actionType: 'SORT_ERROR',
      });

    case actions.RESET_ACTIONTYPE:
      return state.merge({
        actionType: '',
        successMessage: '',
        errorMessage: '',
        status_code: 0,
      });

    default:
      return state;
  }
}
