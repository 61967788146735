import actions from './actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  successMessage: '',
  errorMessage: '',
  actionType: '',
  status_code: 0,
  grades: {},
});

export default function GradeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_GRADE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'CREATE_GRADE_REQUEST',
      });
    case actions.CREATE_GRADE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_GRADE_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.CREATE_GRADE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_GRADE_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.UPDATE_GRADE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'UPDATE_GRADE_REQUEST',
      });
    case actions.UPDATE_GRADE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_GRADE_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.UPDATE_GRADE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_GRADE_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.DELETE_GRADE_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'DELETE_GRADE_REQUEST',
      });
    case actions.DELETE_GRADE_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_GRADE_SUCCESS',
        successMessage: action.message,
        status_code: action.status_code,
      });
    case actions.DELETE_GRADE_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_GRADE_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.GET_GRADE_LIST_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'GET_GRADE_LIST_REQUEST',
      });
    case actions.GET_GRADE_LIST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_GRADE_LIST_SUCCESS',
        grades: action.data,
        status_code: action.status_code,
        successMessage: action.message,
      });
    case actions.GET_GRADE_LIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_GRADE_LIST_ERROR',
        errorMessage: action.error,
        status_code: action.status_code,
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: '',
        successMessage: '',
        errorMessage: '',
        status_code: 0,
      });
    default:
      return state;
  }
}
