import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions'; // Adjust the path if necessary
import AxiosInterceptor from '../../Services/axiosInterceptor';

const createSubjectApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.post(url, body);
  return result;
};

const getSubjectListApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.get(url);
  return result;
};

const updateSubjectApi = async (payload) => {
  const { url, body } = payload;
  const result = await AxiosInterceptor.put(url, body);
  return result;
};

const deleteSubjectApi = async (payload) => {
  const { url } = payload;
  const result = await AxiosInterceptor.delete(url);
  return result;
};

export function* createSubjectSaga() {
  yield takeEvery(actions.CREATESUBJECT_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(createSubjectApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.CREATESUBJECT_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.CREATESUBJECT_ERROR,
          error: 'Failed to create subject. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATESUBJECT_ERROR,
        error:
          'Something went wrong during subject creation. Please try again.',
      });
    }
  });
}

export function* getSubjectListSaga() {
  yield takeEvery(actions.GET_SUBJECT_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(getSubjectListApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.GET_SUBJECT_SUCCESS,
          data: res?.data,
        });
      } else {
        yield put({
          type: actions.GET_SUBJECT_ERROR,
          error: 'Failed to fetch subjects. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_SUBJECT_ERROR,
        error:
          'Something went wrong while fetching subjects. Please try again.',
      });
    }
  });
}

export function* updateSubjectSaga() {
  yield takeEvery(actions.UPDATE_SUBJECT_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(updateSubjectApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.UPDATE_SUBJECT_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.UPDATE_SUBJECT_ERROR,
          error: 'Failed to update subject. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_SUBJECT_ERROR,
        error:
          'Something went wrong while updating the subject. Please try again.',
      });
    }
  });
}

export function* deleteSubjectSaga() {
  yield takeEvery(actions.DELETE_SUBJECT_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(deleteSubjectApi, payload);
      if (res.status_code === 1) {
        yield put({
          type: actions.DELETE_SUBJECT_SUCCESS,
          successMessage: res?.message,
        });
      } else {
        yield put({
          type: actions.DELETE_SUBJECT_ERROR,
          error: 'Failed to delete subject. Please try again.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_SUBJECT_ERROR,
        error:
          'Something went wrong while deleting the subject. Please try again.',
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createSubjectSaga),
    fork(getSubjectListSaga),
    fork(updateSubjectSaga),
    fork(deleteSubjectSaga),
  ]);
}
