import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Typography,
  Tag,
  message,
  Popconfirm,
} from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Redux/Yearofpass/actions';
import config from '../../../Services/siteConfig';

const { Title } = Typography;

const Yearofpass = () => {
  const dispatch = useDispatch();
  const [editingCourse, setEditingCourse] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [form] = Form.useForm(); // Create a form instance
  const { yearofpassdata, successMessage, errorMessage, actionType } =
    useSelector((state) => state.YearofpassReducer);

  const getYearsList = useCallback(() => {
    try {
      dispatch(
        actions.getcreateYearofpass(`${config.getYearofpass}?page=1&limit=20`),
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getYearsList();
  }, [getYearsList]);

  useEffect(() => {
    if (
      actionType === 'UPDATE_YEAR_OF_PASS_SUCCESS' ||
      actionType === 'CREATEYEAR_OF_PASS_REQUEST_SUCCESS' ||
      actionType === 'DELETE_YEAR_OF_PASS_SUCCESS'
    ) {
      message.success(successMessage);
      getYearsList();
      dispatch(actions.resetActionType());
    }
  }, [actionType, successMessage]);

  const handleOk = (values) => {
    try {
      if (editingCourse) {
        const { _id } = editingCourse;
        const yearofpassId = _id;
        const updatedValues = { yearofpassId, ...values };
        console.log(updatedValues);
        dispatch(
          actions.updateYearofpass(config.updateYearofpass, updatedValues),
        );
      } else {
        dispatch(actions.createYearofpass(config.yearofpass, values));
      }
    } catch (error) {
      console.log(error);
    }
    handleCancel();
    setEditingCourse(null); // Clear editingCourse after form submission
  };

  const handleView = (course) => {
    setCurrentCourse(course);
    setIsViewModalVisible(true);
  };

  const handleEdit = (course) => {
    const courseData = {
      ...course,
      year_of_pass: course.year_of_pass, // Map the response data correctly
    };
    setEditingCourse(course);
    setIsModalVisible(true);
    form.setFieldsValue(courseData); // Set the form fields with the mapped data
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setEditingCourse(null);
    setCurrentCourse(null);
    form.resetFields(); // Reset the form fields when the modal is closed
  };
  const handleDelete = (id, is_active) => {
    dispatch(
      actions.deleteYearofpass(
        `${config.deleteYearofpass}?yearofpassId=${id}&is_active=${!is_active}`,
      ),
    );
  };
  const columns = [
    {
      title: 'Year Of Pass',
      dataIndex: 'year_of_pass',
      key: 'year_of_passing',
      sorter: (a, b) => a.year_of_passing - b.year_of_passing,
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <Popconfirm
          title={`Are you sure you want to ${
            record.is_active ? 'deactivate' : 'activate'
          } this year?`}
          onConfirm={() => handleDelete(record._id, record.is_active)}
          okText="Yes"
          cancelText="No"
        >
          <Tag
            color={record.is_active ? 'green' : 'red'}
            style={{ cursor: 'pointer' }} // Adds a pointer cursor to indicate it's clickable
          >
            {record.is_active ? 'Active' : 'Inactive'}
          </Tag>
        </Popconfirm>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Year Of Pass
        </Title>
        <Space>
          <Input.Search
            placeholder="Search Year"
            enterButton
            className="search-input"
          />
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add Year Of Pass
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={yearofpassdata?.yearsOfPassing}
        rowKey="_id"
        className="table-container"
      />

      {/* Add/Edit Modal */}
      <Modal
        title={editingCourse ? 'Edit Year' : 'Add New Year'}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form
          form={form} // Associate the form instance with the Form component
          onFinish={handleOk}
          layout="vertical"
        >
          <Form.Item
            name="year_of_pass"
            label="Year Of Passing"
            rules={[{ required: true, message: 'Please input the year!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {editingCourse ? 'Save Changes' : 'Add Year'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* View Modal */}
      <Modal
        title="View Year Details"
        visible={isViewModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <p>
          <strong>Year of Passing:</strong> {currentCourse?.year_of_passing}
        </p>
        <p>
          <strong>Status:</strong>{' '}
          {currentCourse?.is_active ? 'Active' : 'Inactive'}
        </p>
      </Modal>
    </>
  );
};

export default Yearofpass;
