import actions from './actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  successMessage: '',
  errorMessage: '',
  actionType: '',
  categories: [],
});

export default function CategoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_CATEGORY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'CREATE_CATEGORY_REQUEST',
      });
    case actions.CREATE_CATEGORY_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_CATEGORY_SUCCESS',
        successMessage: action.successMessage,
      });
    case actions.CREATE_CATEGORY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'CREATE_CATEGORY_ERROR',
        errorMessage: action.error,
      });
    case actions.GET_CATEGORY_LIST_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'GET_CATEGORY_LIST_REQUEST',
      });
    case actions.GET_CATEGORY_LIST_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_CATEGORY_LIST_SUCCESS',
        categories: action.categories,
      });
    case actions.GET_CATEGORY_LIST_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'GET_CATEGORY_LIST_ERROR',
        errorMessage: action.error,
      });
    case actions.UPDATE_CATEGORY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'UPDATE_CATEGORY_REQUEST',
      });
    case actions.UPDATE_CATEGORY_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_CATEGORY_SUCCESS',
        successMessage: action.successMessage,
      });
    case actions.UPDATE_CATEGORY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'UPDATE_CATEGORY_ERROR',
        errorMessage: action.error,
      });
    case actions.DELETE_CATEGORY_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'DELETE_CATEGORY_REQUEST',
      });
    case actions.DELETE_CATEGORY_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_CATEGORY_SUCCESS',
        successMessage: action.successMessage,
      });
    case actions.DELETE_CATEGORY_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'DELETE_CATEGORY_ERROR',
        errorMessage: action.error,
      });
    case actions.SORT_REQUEST:
      return Immutable.merge(state, {
        isFetching: true,
        actionType: 'SORT_REQUEST',
      });
    case actions.SORT_SUCCESS:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_SUCCESS',
        categories: action.categories,
      });
    case actions.SORT_ERROR:
      return Immutable.merge(state, {
        isFetching: false,
        actionType: 'SORT_ERROR',
        errorMessage: action.error,
      });
    case actions.RESET_ACTIONTYPE:
      return Immutable.merge(state, {
        actionType: '',
        successMessage: '',
        errorMessage: '',
      });
    default:
      return state;
  }
}
