import React, { useState, useEffect } from 'react';
import {
  MenuOutlined,
  UserOutlined,
  BellOutlined,
  DashboardOutlined,
  BookOutlined,
  LogoutOutlined,
  SettingOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  Button,
  Layout,
  Menu,
  Badge,
  Switch,
  Dropdown,
  Menu as AntMenu,
  Drawer,
} from 'antd';
import { Link, Outlet } from 'react-router-dom';

const { Header, Sider, Content, Footer } = Layout;

const MainLayout2 = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    () => localStorage.getItem('darkMode') === 'enabled',
  );
  const [drawer, setDrawer] = useState(false);
  const [Mobile, setMobile] = useState(window.innerWidth <= 768);

  const menuItems = [
    {
      key: 'dashboard',
      icon: (
        <DashboardOutlined
          style={{ color: isDarkMode ? '#1890ff' : '#1890ff' }}
        />
      ),
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    {
      key: 'MasterData',
      icon: (
        <BookOutlined style={{ color: isDarkMode ? 'skyblue' : 'skyblue' }} />
      ),
      label: 'MasterData',
      children: [
        {
          key: 'categories',
          label: <Link to="/dashboard/Categories">Categories</Link>,
        },
        { key: 'courses', label: <Link to="/dashboard/Course">Courses</Link> },
        { key: 'modules', label: <Link to="/dashboard/Module">Modules</Link> },
        {
          key: 'education',
          label: <Link to="/dashboard/Education">Education</Link>,
        },
        {
          key: 'Yearofpass',
          label: <Link to="/dashboard/Yearofpass">Year of Pass</Link>,
        },
        { key: 'grades', label: <Link to="/dashboard/Grades">Grades</Link> },
        {
          key: 'relationships',
          label: <Link to="/dashboard/Relationships">Relationships</Link>,
        },
        {
          key: 'Job Titles',
          label: <Link to="/dashboard/Jobtitles">Job Titles</Link>,
        },
        {
          key: 'State',
          label: <Link to="/dashboard/State">State</Link>,
        },
        {
          key: 'Country',
          label: <Link to="/dashboard/Country">Country</Link>,
        },
      ],
    },
  ];

  const toggleDarkMode = (checked) => {
    setIsDarkMode(checked);
    localStorage.setItem('darkMode', checked ? 'enabled' : 'disabled');
  };

  useEffect(() => {
    document.body.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const siderWidth = collapsed ? 0 : 200;

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const handleMenuClick = () => {
    if (Mobile) {
      toggleDrawer();
    } else {
      setCollapsed(!collapsed);
    }
  };

  const userMenu = (
    <AntMenu style={{ zIndex: 9999 }}>
      <AntMenu.Item key="profile" icon={<UserOutlined />}>
        Profile
      </AntMenu.Item>
      <AntMenu.Item key="settings" icon={<SettingOutlined />}>
        Settings
      </AntMenu.Item>
      <AntMenu.Divider />
      <AntMenu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </AntMenu.Item>
    </AntMenu>
  );

  return (
    <Layout
      style={{
        minHeight: '100vh',
        background: isDarkMode ? '#333' : '#f0f2f5',
      }}
    >
      <Sider
        width={siderWidth}
        collapsed={collapsed}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          backgroundColor: isDarkMode ? '#000' : '#ffffff',
          borderRight: '1px solid #e8e8e8',
          transition:
            'width 0.3s ease, opacity 0.3s ease, visibility 0.3s ease',
          opacity: collapsed ? 0 : 1,
          visibility: collapsed ? 'hidden' : 'visible',
          display: Mobile ? 'none' : 'block',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            backgroundColor: isDarkMode ? '#000' : '#ffffff',
            padding: '0',
            borderBottom: '1px solid #e8e8e8',
            width: '100%',
            height: '64px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/ecom-assets.samyutha.com/sam.jpeg"
            alt="Logo"
            style={{
              objectFit: 'contain',
              height: '64px',
              width: 'auto',
              maxWidth: '100%',
            }}
          />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={['dashboard']}
          style={{
            height: 'calc(100% - 64px)',
            borderRight: 0,
            color: isDarkMode ? '#fff' : '#595959',
            backgroundColor: isDarkMode ? '#000' : '#ffffff',
          }}
          items={menuItems}
          theme={isDarkMode ? 'dark' : 'light'}
        />
      </Sider>

      <Drawer
        placement="left"
        onClose={toggleDrawer}
        visible={drawer}
        bodyStyle={{
          padding: 0,
          backgroundColor: isDarkMode ? '#000' : '#ffffff',
        }}
        style={{ backgroundColor: isDarkMode ? 'black' : 'white' }}
        closeIcon={
          <CloseOutlined style={{ color: isDarkMode ? '#fff' : '#000' }} />
        }
      >
        <div
          style={{
            textAlign: 'center',
            backgroundColor: isDarkMode ? '#000' : '#ffffff',
            padding: '0',
            borderBottom: '1px solid #e8e8e8',
            width: '100%',
            height: '64px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/ecom-assets.samyutha.com/sam.jpeg"
            alt="Logo"
            style={{
              objectFit: 'contain',
              height: '64px',
              width: 'auto',
              maxWidth: '100%',
            }}
          />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={['dashboard']}
          style={{
            height: 'calc(100% - 64px)',
            borderRight: 0,
            color: isDarkMode ? '#fff' : '#595959',
            backgroundColor: isDarkMode ? '#000' : '#ffffff',
          }}
          items={menuItems}
          theme={isDarkMode ? 'dark' : 'light'}
        />
      </Drawer>

      <Layout style={{ marginLeft: Mobile ? 0 : siderWidth }}>
        <Header
          style={{
            padding: '0 24px',
            background: isDarkMode ? '#000' : '#ffffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #e8e8e8',
            position: 'fixed',
            top: 0,
            left: Mobile ? 0 : siderWidth,
            right: 0,
            zIndex: 1000,
            height: '64px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type="text"
              icon={
                <MenuOutlined
                  style={{ color: isDarkMode ? '#fff' : '#1890ff' }}
                />
              }
              onClick={handleMenuClick}
              style={{
                fontSize: '30px',
                width: 64,
                height: 64,
                color: isDarkMode ? '#fff' : '#1890ff',
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Badge count={5}>
              <Button
                type="text"
                icon={
                  <BellOutlined
                    style={{ color: isDarkMode ? '#fff' : '#1890ff' }}
                  />
                }
                style={{ marginLeft: 16 }}
              />
            </Badge>

            <Dropdown overlay={userMenu} placement="bottomRight">
              <Button
                type="text"
                icon={
                  <UserOutlined
                    style={{ color: isDarkMode ? '#fff' : '#1890ff' }}
                  />
                }
                style={{ marginLeft: 16 }}
              />
            </Dropdown>

            <Switch
              checked={isDarkMode}
              onChange={toggleDarkMode}
              style={{ marginLeft: 16 }}
            />
          </div>
        </Header>
        <Layout style={{ marginTop: '64px' }}>
          <Content
            style={{
              minHeight: 280,
              background: isDarkMode ? '#000' : '#ffffff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              overflow: 'auto',
            }}
          >
            <Outlet />
          </Content>
          <Footer
            style={{
              textAlign: 'center',
              background: isDarkMode ? '#000' : '#ffffff',
              borderTop: '1px solid #e8e8e8',
              padding: '16px',
              position: 'fixed',
              bottom: 0,
              left: Mobile ? 0 : siderWidth,
              right: 0,
            }}
          >
            <span style={{ color: isDarkMode ? 'white' : 'black' }}>
              © 2024 LMS Admin Panel. All Rights Reserved.
            </span>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout2;
