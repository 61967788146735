const actions = {
  CREATE_JOBTITLE_REQUEST: 'CREATE_JOBTITLE_REQUEST',
  CREATE_JOBTITLE_SUCCESS: 'CREATE_JOBTITLE_SUCCESS',
  CREATE_JOBTITLE_ERROR: 'CREATE_JOBTITLE_ERROR',
  GET_JOBTITLE_REQUEST: 'GET_JOBTITLE_REQUEST',
  GET_JOBTITLE_SUCCESS: 'GET_JOBTITLE_SUCCESS',
  GET_JOBTITLE_ERROR: 'GET_JOBTITLE_ERROR',
  UPDATE_JOBTITLE_REQUEST: 'UPDATE_JOBTITLE_REQUEST',
  UPDATE_JOBTITLE_SUCCESS: 'UPDATE_JOBTITLE_SUCCESS',
  UPDATE_JOBTITLE_ERROR: 'UPDATE_JOBTITLE_ERROR',
  DELETE_JOBTITLE_REQUEST: 'DELETE_JOBTITLE_REQUEST',
  DELETE_JOBTITLE_SUCCESS: 'DELETE_JOBTITLE_SUCCESS',
  DELETE_JOBTITLE_ERROR: 'DELETE_JOBTITLE_ERROR',
  SORT_JOBTITLE_REQUEST: 'SORT_JOBTITLE_REQUEST',
  SORT_JOBTITLE_SUCCESS: 'SORT_JOBTITLE_SUCCESS',
  SORT_JOBTITLE_ERROR: 'SORT_JOBTITLE_ERROR',
  RESET_JOBTITLE_ACTIONTYPE: 'RESET_JOBTITLE_ACTIONTYPE',
  createJobtitle: (url, body) => ({
    type: actions.CREATE_JOBTITLE_REQUEST,
    payload: { url, body },
  }),
  getJobtitle: (url) => ({
    type: actions.GET_JOBTITLE_REQUEST,
    payload: { url },
  }),
  updateJobtitle: (url, body) => ({
    type: actions.UPDATE_JOBTITLE_REQUEST,
    payload: { url, body },
  }),
  deleteJobtitle: (url) => ({
    type: actions.DELETE_JOBTITLE_REQUEST,
    payload: { url },
  }),
  sortJobtitle: (url, body) => ({
    type: actions.SORT_JOBTITLE_REQUEST,
    payload: { url, body },
  }),
  resetActionType: () => ({
    type: actions.RESET_JOBTITLE_ACTIONTYPE,
  }),
};

export default actions;
