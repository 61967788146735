import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PublicRoute from '../Routes/Publicroute';
import Login from '../Components/User/login';
import MainLayout2 from '../Components/Layout/MainLayout2';
import Categories from '../Components/Screens/CourseCatagorey';
import Course from '../Components/Screens/Courses/Course';
import Module from '../Components/Screens/Modules/module';
import Education from '../Components/Screens/Education/Education';
import Relationships from '../Components/Screens/ParentTypes/Parenttypes';
import Jobtitles from '../Components/Screens/JobTitles/jobtitle';
import Grades from '../Components/Screens/Grades/Grades';
import Dashboard from '../Components/Screens/Dashboard/dashboard';
import State from '../Components/Screens/State/State';
import Country from '../Components/Screens/Country/Country';
import Yearofpass from '../Components/Screens/Yearofpass/Yearofpass';
function App() {
  return (
    <Routes>
      <Route path="/" element={<PublicRoute component={Login} />} />
      <Route
        path="/dashboard"
        element={<PublicRoute component={MainLayout2} />}
      >
        <Route index element={<Dashboard />} />
        <Route
          path="Categories"
          element={<PublicRoute component={Categories} />}
        />
        <Route path="Course" element={<PublicRoute component={Course} />} />
        <Route path="Module" element={<PublicRoute component={Module} />} />
        <Route
          path="Education"
          element={<PublicRoute component={Education} />}
        />
        <Route path="Grades" element={<PublicRoute component={Grades} />} />
        <Route
          path="Relationships"
          element={<PublicRoute component={Relationships} />}
        />
        <Route
          path="Jobtitles"
          element={<PublicRoute component={Jobtitles} />}
        />
        <Route path="State" element={<PublicRoute component={State} />} />
        <Route path="Country" element={<PublicRoute component={Country} />} />
        <Route
          path="Yearofpass"
          element={<PublicRoute component={Yearofpass} />}
        />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
