import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Typography,
  Row,
  Col,
  Tag,
  message,
  Dropdown,
  Menu,
} from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Redux/Grades/actions';
import config from '../../../Services/siteConfig';

const { Title, Paragraph } = Typography;

const Grades = () => {
  const dispatch = useDispatch();
  const { grades, successMessage, actionType, status_code } = useSelector(
    (state) => state.GradeReducer,
  );
  const gradesList = grades?.grades || [];
  const [form] = Form.useForm();

  const [editingCourse, setEditingCourse] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);

  const handleSearch = (value) => {
    // Implement search logic if needed
  };

  const handleEdit = (course) => {
    console.log('Editing course:', course);
    setEditingCourse(course);
    setIsModalVisible(true);
  };

  const handleView = (course) => {
    setCurrentCourse(course);
    setIsViewModalVisible(true);
  };

  const handleCancel = () => {
    console.log('Canceling edit');
    form.resetFields(); // Reset form fields
    setIsModalVisible(false);
    setEditingCourse(null);
    setCurrentCourse(null);
  };

  const handleOk = (values) => {
    console.log('Form values:', values);
    if (editingCourse) {
      const payload = { ...values, id: editingCourse._id };
      dispatch(actions.updateGrade(config.gradeupdate, payload));
    } else {
      dispatch(actions.createGrade(config.creategrade, values));
    }
    handleCancel();
  };

  const getgrade = useCallback(() => {
    dispatch(
      actions.getGradeList(`${config.getgradelist}?page=${1}&limit=${20}`),
    );
  }, [dispatch]);

  useEffect(() => {
    getgrade();
  }, [dispatch, getgrade]);

  useEffect(() => {
    if (actionType === 'CREATE_GRADE_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getgrade();
      dispatch(actions.resetActionType());
    } else if (actionType === 'CREATE_GRADE_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, getgrade, dispatch]);

  const handleStatusToggle = (record) => {
    const newStatus = !record.is_active;

    Modal.confirm({
      title: `Are you sure you want to set this grade to ${newStatus ? 'Active' : 'Inactive'}?`,
      onOk() {
        const payload = { id: record._id, is_active: newStatus };
        console.log('Status payload:', payload);
        dispatch(
          actions.deleteGrade(
            `${config.gradedelete}?id=${record._id}&is_active=${newStatus}`,
          ),
        );
      },
    });
  };

  useEffect(() => {
    if (actionType === 'DELETE_GRADE_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getgrade();
      dispatch(actions.resetActionType());
    } else if (actionType === 'DELETE_GRADE_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, dispatch, getgrade]);

  useEffect(() => {
    if (actionType === 'UPDATE_GRADE_SUCCESS' && status_code === 1) {
      message.success(successMessage);
      getgrade();
      dispatch(actions.resetActionType());
    } else if (actionType === 'UPDATE_GRADE_SUCCESS' && status_code === 0) {
      message.error(successMessage);
    }
  }, [actionType, successMessage, status_code, getgrade, dispatch]);

  const columns = [
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      sorter: (a, b) => a.grade.localeCompare(b.grade),
    },
    {
      title: 'Range of Grade',
      dataIndex: 'range_of_grade',
      key: 'range_of_grade',
      sorter: (a, b) => a.grade.localeCompare(b.grade),
    },
    {
      title: 'Status',
      key: 'is_active',
      dataIndex: 'is_active',
      render: (is_active, record) => (
        <Tag
          color={is_active ? 'green' : 'red'}
          onClick={() => handleStatusToggle(record)}
          style={{ cursor: 'pointer' }}
        >
          {is_active ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
            className="view-button"
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            className="edit-button"
          />
        </Space>
      ),
    },
  ];

  const ViewModal = ({ course, visible, onCancel }) => {
    if (!course) return null;

    const modalStyle = {
      backgroundColor: '#f0f2f5',
    };

    const sectionStyle = {
      backgroundColor: '#ffffff',
    };

    const titleStyle = {
      color: '#1890ff',
      marginBottom: '20px',
    };

    const infoItemStyle = {
      fontSize: '16px',
      marginBottom: '10px',
    };

    return (
      <Modal
        title={
          <Title level={3} style={titleStyle}>
            Detailed view
          </Title>
        }
        visible={visible}
        footer={
          <Button onClick={onCancel} type="primary" size="large">
            Close
          </Button>
        }
        onCancel={onCancel}
        width={500}
        bodyStyle={modalStyle}
        className="view-modal"
      >
        <div style={sectionStyle}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Paragraph style={infoItemStyle}>
                <strong>Grade Code/ID:</strong> {course.grade}
              </Paragraph>
              <Paragraph style={infoItemStyle}>
                <strong>Range of Grades/Percentage:</strong>{' '}
                {course.range_of_grade}
              </Paragraph>
              <Paragraph style={infoItemStyle}>
                <strong>Status:</strong>{' '}
                {course.is_active ? 'Active' : 'Inactive'}
              </Paragraph>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
      <Menu.Item key="3">Option 3</Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="page-title">
        <Title level={2} className="tittle-name">
          Grade Details
        </Title>
        <Space>
          <Input.Search
            placeholder="Search courses"
            onSearch={handleSearch}
            enterButton
            onChange={(e) => handleSearch(e.target.value)}
            className="search-input"
          />
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="primary" className="dropdown-button">
              Filtered By
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="add-button"
          >
            Add Grade
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={gradesList}
        rowKey="_id"
        className="table-container"
        pagination={{
          total: grades?.totalRecords || 0,
        }}
      />
      <Modal
        title={editingCourse ? 'Edit Grade' : 'Add New Grade'}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="modal-custom"
      >
        <Form
          form={form} // Pass the form instance here
          initialValues={editingCourse || {}}
          onFinish={handleOk}
          layout="vertical"
        >
          <Form.Item
            name="grade"
            label="Grade Code/ID"
            rules={[
              {
                required: true,
                message: 'Please input the grade code/ID!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="range_of_grade"
            label="Range of Grades/Percentage"
            rules={[
              {
                required: true,
                message: 'Please input the range of grades or percentage!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="modal-footer-button"
            >
              {editingCourse ? 'Save Changes' : 'Add Grade'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <ViewModal
        course={currentCourse}
        visible={isViewModalVisible}
        onCancel={handleCancel}
      />
    </>
  );
};

export default Grades;
